export default {
  menu: {
    about: 'About',
    projects: 'Projects',
    blogs: 'Blogs',
    company: 'Company',
    team: 'Team',
    vacancy: 'Jobs',
    contacts: 'Contacts',
    directions: 'Directions',
    portfolio: 'Cases',
    reviews: 'Reviews',
    blog_kotlin: 'Kotlin Multiplatform',
    blog_mobile: 'Mobile Development',
    blog_uiux: 'UX / UI Design',
    blog_company: 'Company Life',
    order_app: 'Order an app',
    back: 'Back',
  },
  main: {
    not_found: 'Page not found',
    page_missing: 'Oops. This page has gone missing',
    back_to_home: 'Back to homepage',
    back: 'back',
  },
  title: {
    main: 'Main',
    not_found: 'Not found',
  },
  blog: {
    show_more: 'Show more',
    show_previous_posts: 'Show previous posts',
    subscribe_text: 'Subscribe to blog updates',
    subscribe: 'Subscribe',
    success: 'You have successfully subscribed',
    tags: {
      'kotlin-multiplatform': 'Kotlin Multiplatform',
      mobile: 'Mobile Development',
      'ui-ux': 'UX / UI Design',
      'company-life': 'Company Life',
    },
  },
  portfolio: {
    show_more: 'Show more',
    show_previous_projects: 'Show previous projects',
  },
  about: {
    title: 'We are Kotlin Multiplatform experts',
    top_projects: 'Top cases',
    link: 'SHOW ALL',
    projects: 'Cases',
    awards: 'Awards',
    award_1: 'TOP 10 Rating Runeta Best Siberial Mobile Developers 2018',
    award_2: 'TOP 100 Best Agencies in Russia by Tagline',
    award_3: 'TOP 10 Ruward Best Frontend Developers 2018',
    award_4: 'TOP 50 Manifest Best Mobile Developers in UK (February 2019)',
    top_directions: 'Top Directions',
    directions: 'Directions',
    expertise: {
      title: 'Expertise',
      description_1: {
        text: 'We are experts in the development of mobile applications on',
        link_text: 'Kotlin Multiplatform',
      },
      description_2: {
        text: 'is a technology that allows you to create native mobile applications with a unified business logic, which improves the reliability of the application and shortens the development time.',
        link_text: 'Kotlin Multiplatform',
      },
    },
    ratings: 'Ratings',
    we_in_media: 'We in mass media',
    reviews: 'Reviews',
  },
  vacancies: {
    title: 'We need',
    seo: {
      title: 'Open Vacancies for IT professionals in IceRock Development',
      description:
        'Great job opprotunities for IT professionals in IceRock development',
      keywords:
        'icerock, app development, vacancies, ios, android developer, coders, kotlin, jobs',
    },
    detail_information: 'Detail information',
    success_title: 'Thank you for contacting us!',
    success_text: 'We will definitely contact you',
    ok: 'OK',
    we_need: 'We need',
    freeform: {
      title: 'We needed specialists',
      description: 'You can offer us your candidacy and we will contact you',
      tell: 'Tell us about yourself',
      send_request: 'Send request',
    },
    page: {
      title: 'Send a request',
      send_request: 'Send',
    },
  },
  directions: {
    title:
      'We have accumulated expertise in different areas of business over the years',
    seo: {
      title: 'Full stack software development company IceRock',
      description:
        'App development scope for actual buisness needs by IceRock Development',
      keywords:
        'icerock, app development, directions, cdto, fintech, kotlin, kmm, backend, design, iot, delivery',
    },
    detail_information: 'Detail information',
    count: '{count, plural, =0 {no cases} one {# case} other {# cases}}',
    reviews: 'Reviews',
    blog: 'Blog',
    i_want_mobile_app: 'I want mobile app',
    areas_of_work_list: 'Areas of work list',
    delivery: 'Delivery',
    ecommerce: 'E-commerce',
    entertainment: 'Entertainment',
    fintech: 'Fintech',
    iiko: 'IIKO',
    internal: 'Internal',
    iot: 'IOT',
    promotional: 'Promotional',
  },
  contacts: {
    title: 'Our Contacts',
    seo: {
      title: 'Contacts, phones, emails of IceRock Development',
      description:
        'Contacts · Kazakhstan · +7 (705) 8187 195 · info@icerockdev.com',
      keywords:
        'icerock, app, mobile, cross platform development company, contacts, phone, email',
    },
    button: 'Сontact us',
    kazakhstan: 'Kazakhstan',
    phone_erevan: 'Phone',
    email: 'Email',
    phone: 'Phone',
    name: 'Name',
    hr_name: 'HR-agent',
    fill_this_field: 'Please, fill this field',
    send_request: 'Send request',
    resume: 'Add resume',
    kazakhstan_address: '280 Baizakova street. Kazakhstan, Almaty',
  },
  reviews: {
    title: 'Company reviews',
    seo: {
      title: 'Reviews of IceRock Development clients',
      description:
        'Read reviews of IceRock Development clients on leading B2B rationgs and reviews service Clutch',
      keywords:
        'customer, reviews, clutch, app development,company, ios, android, mobile, icerock',
    },
    quality: 'Quality',
    schedule: 'Schedule',
    cost: 'Cost',
    refer: 'Willing to refer',
    read_full: 'Read full review',
    watch_case: 'Watch case',
  },
  terms: {
    text: 'We use cookies developed by our experts and third parties to analyze events on our website, which allows us to improve user interaction and service.<br />By continuing to browse our site, you accept the terms of use.<br />See our',
    text_short: 'We use cookies',
    link_text_more: 'What does it mean?',
    link_text_short: '<center>Privacy Policy for more details</center>',
    link_text: 'Privacy Policy for more details',
    link_url: '/terms.pdf',
    button: 'OK',
  },
  order: {
    title: 'Let’s create successful business stories together!',
    subtitle: 'You set the tasks. We find the solutions',
    header:
      'Sign up for a free consultation and we will tell you how the application will change your project',
    subscribe: 'Subscribe',
    name: 'Name',
    company: 'Company',
    email: 'Email',
    phone: 'Phone',
    textarea_placeholder:
      'Tell us about your project. Describe the idea of your app. How will it help your customers?',
    send_request: 'Send a request',
    additional_questions: 'Additional questions',
    additional_subtitle:
      'You can answer additional questions so that we can more accurately evaluate your idea and get in touch with you faster!',
    feature_list: 'List the main killer features',
    feature_placeholder:
      'Describe what distinguishes your application among competitors or how it will interest users',
    materials: 'Do you have any materials on the project?',
    technical_task: 'Technical task',
    technical_task_link: 'Insert a link to your technical task',
    design: 'Design',
    design_link: 'Insert a link to your design',
    technology_stack: 'Technology stack',
    technology_stack_link: 'Insert a link to your technology stack',
    prototypes: 'Prototype',
    prototypes_link: 'Insert a link to your prototype',
    characteristics: 'Main technical characteristics of the app',
    budget_title: 'Estimated budget',
    budget_1: 'Up to $10,000',
    budget_2: 'From $10,000 to $25,000',
    budget_3: 'From $25,000 to $50,000',
    budget_4: 'Over $50,000',
    success_title: 'Your request has been sent successfully',
    success_text: 'Our sales manager will contact you to discuss your project',
    ok: 'OK',
    document: 'Add a document',
    maxSize:
      'The maximum file size has been exceeded. Try attaching the link to the document above',
    maxLength:
      'The maximum number of files has been exceeded. Try attaching the link to the document above',
    button: 'Next step',
  },
  errors: {
    fill_this_field: 'Please, fill this field',
    fill_all_fields: 'Please, fill all required fields',
    error_cant_upload: "Can't upload file",
    error_too_big: 'Should be less than 20 Mb',
    error_incorrect_mime: 'Should be image or document',
    incorrect_captcha: 'Check it again',
    oops_error: 'Oops, something went wrong',
    gatsby_endpoint_error: 'Specify GATSBY_MAIL_ENDPOINT at .env!',
  },
  cases: {
    blog: {
      email: 'Email*',
    },
    seo: {
      title: 'Apps developed by IceRock Development',
      description:
        'IceRock Development created dosens of cross-patform applications for various industries: fintech, banking, investment, cryptourrency and blockchain, insuarance, foodtech',
      keywords:
        'mobile, app development company, solutions, icerock, ios, android, cases, portfolio, fintech, applications custom',
    },
    restocare: {
      title: {
        iiko: 'IIKO',
        title:
          'Own mobile application for restaurants with delivery and pickup functions',
        fast: 'Integration with&nbsp;iiko&nbsp;',
        free_launch: 'Free launch',
        ios_android: 'For Apple and&nbsp;Android',
      },
      cards: {
        title: 'A new service for your guests',
        subtitle: 'Attract even more customers to your restaurant',
        card_title_1: 'Convenient delivery and&nbsp;pickup',
        card_text_1:
          'Now your guests can order their favorite meals home or pick them up on the way, quickly and safely.',
        card_title_2:
          'Integration with&nbsp;the iiko restaurant management system&nbsp;included',
        card_text_2:
          'Instant receipt of orders at&nbsp;checkout, courier auto-notification. You&nbsp;will be able to assemble the order&nbsp;without&nbsp;losing an&nbsp;extra second.',
        card_title_3: 'Tracking of orders on&nbsp;the map',
        card_text_3:
          'You can track couriers on&nbsp;the map, as well as&nbsp;their delays, and calculate average delivery times.',
      },
      launch: {
        title: 'We will launch your application as soon as possible',
        term_1:
          'Launch period 1 week + registration in the AppStore for iOS applications.',
        term_2:
          'The only payment at launch is $ 99 for registration in the AppStore and $ 25 for registration in GooglePlay.',
        term_3: 'You pay 5% of the cost of each order plus acquiring.',
      },
      contact: {
        title: 'I want such an application',
        your_name: 'Your name*',
        company_name: 'Company*',
        email: 'Email',
        send: 'Send',
        terms:
          'By clicking the SEND button, you consent to the processing of your',
        personal_data: 'personal data',
        fill_this_field: 'Please fill in this field',
        success_title: 'Thank you for contacting us!',
        success_subtitle: 'Our manager will contact you soon',
      },
      copyright: '&copy;&nbsp;2021 Company IceRock Development',
    },
    design: {
      modalTitle: 'Design request',
      blog: {
        title: 'Blog',
      },
      portfolio: {
        title: 'Cases',
      },
      reviews: {
        title: 'Reviews',
      },
      form: {
        title: 'Расскажите о вашем проекте',
        button: 'Отправить',
        checkbox_text_start: 'Согласие на',
        checkbox_text_end: 'обработку персональных данных',
        checkbox_error: 'Примите согласие на обработку персональных данных',
        name: 'Ваше имя*',
        phone: 'Телефон*',
        email: 'Email',
        message: 'Сообщение',
        file: 'Add a document',
      },
      footer: {
        phone: '+37494044318',
        email: 'mobiledev@icerockdev.com',
      },
      title: {
        phone: '+37494044318',
        email: 'mobiledev@icerockdev.com',
        title: 'Modern app design adapted for mobile development',
        desc: 'IceRock is a mobile app development studio. We have two main teams: the development team and the design team. Both teams work very closely together, which means our designers not only create stunning visuals but also understand how the developers turn them into code. The result is a mobile app that both looks and works great.',
        button: 'MAKE A REQUEST',
      },
      reasons: {
        reasons_1: {
          desc: '6 years of mobile app development, 60+ apps',
        },
        reasons_2: {
          desc: 'In-house testers',
        },
        reasons_3: {
          desc: 'Real user focus group studies',
        },
      },
      promises: {
        promise_0: {
          title: {
            colored: 'Forming',
            rest: 'a Shared Vision for the Product',
          },
          paragraph_0: {
            textStart:
              'Before we start our work, we prepare a vision document detailing the project: its goals, challenges, any key features, and the initial design sketches. We form the vision incrementally: we show you how we understand the task, and you make changes if the initial draft has any inaccuracies. ',
          },
          paragraph_1: {
            textStart:
              'We only get down to work once the document has been agreed. This is important to ensure we have a unified design vision from the very start and minimize the amount of work that needs redoing.',
          },
        },
        promise_1: {
          title: { colored: 'Help', rest: 'with Developing your Concept' },
          paragraph_0: {
            textStart:
              'We can work with you to prepare a technical specification and a design prototype. We will study your competitors, offer you references for current trends, and prepare several design concepts. The one you like the most is the one we will build.',
          },
        },
        promise_2: {
          title: {
            colored: 'Faster',
            rest: 'than Most Design Studios',
          },
          paragraph_0: {
            textStart:
              'In 6 years, we have completed over 60 projects. Over this time we have developed an extensive design system that can be adapted to various tasks. This means that we don’t start our designs from scratch every time. Instead, we use solutions that have already helped us with similar problems.',
          },
          paragraph_1: {
            textStart:
              'Sometimes you need a design or even a prototype for a product at short notice, for instance, to hold a presentation for investors. Even if you come to us with a task',
            textBold: 'just 2 weeks',
            textEnd:
              'before your deadline, we’ll be able to prepare a visual solution in time.',
          },
        },
        promise_3: {
          title: {
            colored: 'Design',
            rest: 'that Easily Turns into Code',
          },
          paragraph_0: {
            textStart:
              'Some designers lack an understanding of how their work will come to life in code. They let their imagination run wild and build a user interface that’s too hard, too expensive, or completely impossible to implement. This forces developers to simplify the UI or completely rework it.',
          },
          paragraph_1: {
            textStart:
              'Our design will be ready for implementation by any developer. Our designers collaborate closely with developers and make their designs easy to transform into code.',
          },
        },
        promise_4: {
          title: {
            colored: 'Focus Group',
            rest: 'Reviews and Bug Tests',
          },
          paragraph_0: {
            textStart:
              'We do a lot of groundwork to make our app attractive to users. This involves focus group reviews and target audience testing.',
          },
          paragraph_1: {
            textStart:
              'If you have potential users, we will develop a testing methodology, do the tests, and review the results. User feedback and comments will be taken into consideration in the final product.',
          },
          paragraph_2: {
            textStart:
              'To identify bugs, we test our apps in all kinds of scenarios. This is done by our in-house testers.',
          },
        },
        promise_5: {
          title: { colored: 'Interactive Prototype', rest: 'Development' },
          paragraph_0: {
            textStart: 'In addition to design, we also offer',
            textLink: 'working prototypes.',
            textEnd:
              'This is an advanced level. The prototype will allow you to test ideas, experiment with the design, and identify errors in the logic even before development starts. It will also make it easier to demonstrate how things are going to work.',
          },
        },
      },
      sale: {
        title: 'Первый экран дизайна - бесплатно!',
        desc: 'С момента возникновения идеи создания проекта до момента его использования пользователем, продукт проходит несколько этапов разработки.',
      },
      process: {
        title: 'Процесс разработки',
        step_1: {
          number: '01',
          title: 'Разработка дизайн-концепта',
          desc: 'Предлагаем дизайн основных экранов, согласовываем с вами. Может протекать параллельно с разработкой UX-прототипа.',
        },
        step_2: {
          number: '02',
          title: 'Анализ требований, разработка ТЗ',
          desc: 'Вы описываете нам вашу идею, мы ее обсуждаем, разделяем на этапы, согласовываем роадмап.',
        },
        step_3: {
          number: '03',
          title: 'Разработка UX-прототипа',
          desc: 'Присылаем вам прототип, который показывает полный функционал оговоренный на первом этапе.',
        },
        step_4: {
          number: '04',
          title: 'Разработка дизайна',
          desc: 'После того, как UX-прототип и концепт утверждены, начинается разработка полноценного дизайна всего приложения.',
        },
        step_5: {
          number: '05',
          title: 'Подготовка дизайна для разработчика',
          desc: 'Платформы имеют уникальные требования. Мы экономим время разработчиков, правильно подготавливая материалы.',
        },
        step_6: {
          number: '06',
          title: 'Тестирование / презентация продукта',
          desc: 'Подготовка продукта перед тестированием на фокус группе или презентацией для инвесторов.',
        },
      },
      benefits: {
        title: 'Программисты точно скажут нам спасибо!',
        desc: 'Серьезно подходим к подготовке дизайна для передачи в разработку, что способствует быстрому внедрению программистов в проект и экономии времени на разработку.',
        benefits_1: {
          title: 'Экономим время разработки',
          desc: 'Правильно подгатавливая исходные материалы проекта, мы экономим до 50% времени на разработку.',
        },
        benefits_2: {
          title: 'Упрощяем погружение в проект',
          desc: 'Правильно подгатавливая исходные материалы проекта, мы экономим нервы и мотивацию разработчика.',
        },
        benefits_3: {
          title: 'Скидка 20%',
          desc: 'При заказе услуг наших программистов вы гарантировано получаете скидку 20% на разработку.',
        },
      },
      faq: {
        title: 'Частые вопросы',
        questions: {
          question_1: 'Что необходимо для старта дизайна?',
          answer_1:
            'Вы можете прийти к нам, как со сформированным вижном проекта и описанием бизнес-процессов, так и с “голой” идеей, которую мы проапгрейдим в процессе первоначальных обсуждений проекта, учитывая ваши пожелания, наши профессиональные знания и последние тренды рынка.',
          question_2: 'Как формируется стоимость дизайна?',
          answer_2: 'Binance Spot и Binance Futures.',
          question_3: 'Сколько вариантов дизайна мы делаем?',
          answer_3:
            'Да, если у неё есть взаимодействие через программный интерфейс (API) и все необходимые методы. Напишите нам, какие биржи вам интересны и мы подскажем какие можно подключить',
        },
      },
    },
    delivery: {
      get_advice: 'To get the consultation',
      find_more: 'Find out more',
      title: {
        text: 'Sell online and retain customers ',
        text_condensed: 'with a mobile order and delivery application',
        note: 'Three days development term (or little longer). “Box” (ready-maid or retail) solution costs three times less than the custom-designed one.',
      },
      reasons: {
        title: 'How does my business benefit from the app?',
        card_1: 'Restaurants',
        card_2: 'Pharmacies',
        card_3: 'Shops',
        reason_1__title: 'Safety',
        reason_1:
          'Creates a secure alternative to offline shopping during period of quarantine',
        reason_2__title: 'Delivery',
        reason_2:
          'Helps to arrange delivery – can be integrated with Dostavista or other local courier services',
        reason_3__title: 'Customers attraction',
        reason_3: 'Retains customers and helps to find new ones',
        reason_4__title: 'Loyalty',
        reason_4:
          'Increases customer loyalty - they feel comfortable while ordering and enjoy watching and reading relevant content.',
        reason_5__title: 'Average check growth',
        reason_5:
          'Increases purchase amount and number by means of personalized goods selection and informing on sales, discounts and special offers.',
      },
      graphs: {
        title: 'Why is it important to get the app now?',
        description:
          'Customers’ behaviour will change after the quarantine ceases. Understanding of the advantages of delivery services is expected. Customers will get used to ordering online. By that time, many companies will have mobile apps already made and delivery schemes arranged. Without these, you will find hard to adapt to new circumstances and compete for customers attention. Lagging behind the realities of time is not a good idea.',
        graph_1_alt: 'Product delivery market',
      },
      interaction: {
        title:
          'Our application provides ongoing interaction between you and your clients.',
        card_1_title: 'Push notifications',
        card_1:
          'Buyers receive prompt info on new products. The goods’ assortment in the app gets automatic updates by your CRM.',
        card_2_title: 'Entertaining content',
        card_2:
          'You can add engaging content to your application such as video recipes, reviews, comments, chefs’ on-line lessons.',
        card_3_title: 'Customer base',
        card_3:
          'You can import your own address database. Thus, keeping customers informed on discounts and individual offers.',
        card_4_title: 'Bonus program',
        card_4:
          'The application lets you receive customer loyalty by awarding points or promo-codes.',
      },
      app: {
        title: 'We make the application simple, but handy and useful',
        description:
          'Both your customers and your employees can use it easily. There are three interfaces:',
        for_client: 'Customer',
        for_constructor: 'Packager',
        for_courier: 'Courier',
      },
      how: {
        title: 'How does it work?',
        description:
          'User can play any of the three roles. Each role can be monetized; it can be a part of joint infrastructure, a separate independent unit or a partner',
        customer: 'Customer',
        platform: 'Platform',
        shop: 'Shop',
        storage: 'Storage',
        courier: 'Courier',
        goods_selection: 'Goods selection',
        order_processing: 'Order processing and payment',
        packaging: 'Packaging',
        delivery: 'Delivery',
      },
      where: {
        title: 'What OS does our app work on?',
        p1: 'We develop apps for iOS and Android',
        p2: 'We use Kotlin multiplatform development',
        p3: 'It allows creating of one application for eight platforms at the same time',
      },
      risks: {
        title: 'All the risks are foreseen',
        card1:
          'We have extensive development experience and ready-made platforms. We create apps rapidly and attune them for a specific business needs.',
        card2:
          'We bring the app into the market quickly, in three business days.',
        card3:
          'Our apps fit the local legislation. We thoroughly take account of all legal aspects, so you can avoid issues with tax service and other state authorities.',
      },
      about: {
        title: 'Who are we?',
        subtitle:
          'We are focused on mobile apps for Android and iOS. Our knowledge and experience are strong enough to meet challenges and produce top-quality, convenient and efficient mobile applications.',
        pogrebnyak: 'Aleksandr Pogrebnyak',
        director: 'Director',
        getmanov: 'Valeriy Ghetmanov',
        csm: 'Customer Service Manager',
        mikhailov: 'Aleksey Mikhaylov',
        techdir: 'Technical director',
        grebenschikov: 'Yevgeniy Grebenschikov',
        artdir: 'Creative director',
        tchernov: 'Andrey Chernov',
        ios: 'Head of iOS Team',
        shvedov: 'Aleksey Shvedov',
        web: 'Head of Web Team',
      },
      launch: {
        title: 'We provide clear and transparent launch cycle',
        subtitle: 'Software development takes three days (or little longer)',
        install: 'Installation',
        launch: 'Launch',
        solution: 'All-in-one marketing solution is available',
        mailout: 'Initial email outreach through the customer list',
        landing: 'Landing pages',
        ads: 'Advertising campaign in Yandex and Google',
      },
      showcase: {
        title: 'We have developed the application for',
        case_1_title: 'PrimeTime',
        case_1_info: 'Home-made food delivery',
        case_1_url: 'https://primetimecoffee.ru/',
        case_2_title: 'ServedIn',
        case_2_info:
          'The application allows you to order meals to your apartment',
        case_2_url: ' ',
        case_3_title: 'Delivery',
        case_3_info: 'Instant product delivery service',
        case_3_url: ' ',
        launch_soon: 'Launches soon',
      },
      solution: {
        title:
          'We are offering either software solutions for your personal use or by subscription',
        saas: {
          title: 'SAAS',
          p1: 'Mobile app in your corporate style',
          p2: 'All data and server are hosted by the developer',
          p3: 'Three days development term (or little longer)',
          p4: 'Initial cost from €2,000',
        },
        box: {
          title: '«Box» solution',
          p1: 'Mobile app in your corporate style',
          p2: 'All data and server are hosted by the client',
          p3: 'You can integrate any external service',
          p4: 'Two weeks development term (or little longer), cost from €10,000',
        },
        custom: {
          title: 'Custom-designed application',
          p1: 'Developed «from the ground up» to meet your specific requirements',
          p2: 'All data and server are hosted by the client',
          p3: 'You can integrate any external service',
          p4: 'Four months development term (or little longer), cost from €40,000',
        },
      },
      contacts: {
        title: 'Consultation request',
        subtitle: 'Our manager will contact you soon',
        name: 'Name*',
        phone: 'Phone*',
        comment: 'Comment',
        send: 'Send',
        required: 'Please, fill this field',
        thanks: 'Thank you!',
        fileName: 'Add a document',
        thanks_description: 'Our manager will contact you soon',
      },
    },
    crypto: {
      header: {
        crypto: 'Crypto',
        title: 'Разработка торговых ботов по вашему алгоритму',
        desc: 'Зарабатывайте больше, автоматизировав любую торговую стратегию',
        button: 'Подробнее',
      },
      cards: {
        card_title_1: 'Лёгкий старт',
        card_text_1: 'Всего несколько простых вопросов для старта бота',
        card_title_2: 'Полная автоматизация',
        card_text_2:
          'Binance, lorem ipsum dolor sit amet, consectetur adipiscing',
        card_title_3: 'Детальная статистика',
        card_text_3:
          'Binance, lorem ipsum dolor sit amet, consectetur adipiscing',
        card_title_4: 'Гибкая кастомизация',
        card_text_4: 'Внесём изменения в алгоритм, отображение, интеграцию',
      },
      steps: {
        title: 'Как это работает?',
        step_1: {
          number: '01',
          title: 'Выберите оболочку для управления ботом',
          desc: 'Binance, lorem ipsum dolor sit amet, consectetur adipiscing',
        },
        step_2: {
          number: '02',
          title: 'Определите торговый алгоритм',
          desc: 'Binance, lorem ipsum dolor sit amet, consectetur adipiscing',
        },
        step_3: {
          number: '03',
          title: 'Выберите биржу для торговли бота',
          desc: 'Binance, lorem ipsum dolor sit amet, consectetur adipiscing',
        },
        step_4: {
          number: '04',
          title: 'Мы это всё собираем в один продукт',
          desc: 'Binance, lorem ipsum dolor sit amet, consectetur adipiscing',
        },
        step_5: {
          number: '05',
          title: 'Вы пассивно зарабатываете деньги',
          desc: 'Binance, lorem ipsum dolor sit amet, consectetur adipiscing',
        },
      },
      realizations: {
        realizations_1: {
          number: 'realizations #1',
          title: 'Grid-бот',
          desc: 'Сеточная стратегия работает с отложенными лимитными ордерами (limit orders) на покупку и продажу по заранее определенным цена. Выбираемый пользователем бота ценовой диапазон разбивается на задаваемое им же число уровней (grids) и создает таким образом так называемую сетку из ордеров. Именно поэтому данный метод называется Сеточная стратегия или Grid Strategy. Прибыль достигается за счет продажи-покупки определенного количества по мере движения курса',
        },
        realizations_2: {
          number: 'realizations #2',
          title: 'Метод Мартингейла',
          desc: 'Мартингейл - это стратегия усреднения затрат. Это происходит путем «удвоения риска» при убыточных сделках. Это приводит к снижению вашей средней цены входа. Прибыль достигается при развороте движения рынка',
        },
      },
      contact: {
        title: 'Хочу такое приложение',
        your_name: 'Ваше имя*',
        company_name: 'Компания*',
        email: 'Email',
        send: 'Отправить',
        terms:
          'Нажимая кнопку &laquo;ОТПРАВИТЬ&raquo;, вы&nbsp;даете согласие на&nbsp;обработку своих&nbsp;',
        personal_data: 'персональных данных',
        fill_this_field: 'Заполните это поле',
        success_title: 'Спасибо за&nbsp;обращение!',
        success_subtitle:
          'Наш менеджер свяжется с&nbsp;вами в&nbsp;ближайшее время',
      },
      faq: {
        faq: 'FAQ',
        title: 'Частые вопросы',
        questions: {
          question_1: 'Как подключается биржа?',
          answer_1:
            'Firstly, Cryptobot got the Money Services Business license issued by FinCEN. Secondly, as one of the biggest brokers in Binance, Cryptobot aggregated the liquidity from Binance and Huobi, and your funds are securely stored in them. Last but not least, Pionex keeps 100% reserve, ready for immediate withdrawal on demand.',
          question_2: 'Какие биржи уже подключены?',
          answer_2:
            'Firstly, Cryptobot got the Money Services Business license issued by FinCEN. Secondly, as one of the biggest brokers in Binance, Cryptobot aggregated the liquidity from Binance and Huobi, and your funds are securely stored in them. Last but not least, Pionex keeps 100% reserve, ready for immediate withdrawal on demand.',
          question_3: 'А можно ли подключить другую биржу?',
          answer_3:
            'Firstly, Cryptobot got the Money Services Business license issued by FinCEN. Secondly, as one of the biggest brokers in Binance, Cryptobot aggregated the liquidity from Binance and Huobi, and your funds are securely stored in them. Last but not least, Pionex keeps 100% reserve, ready for immediate withdrawal on demand.',
          question_4: 'Возможно ли реализовать сторонние интеграции?',
          answer_4:
            'Firstly, Cryptobot got the Money Services Business license issued by FinCEN. Secondly, as one of the biggest brokers in Binance, Cryptobot aggregated the liquidity from Binance and Huobi, and your funds are securely stored in them. Last but not least, Pionex keeps 100% reserve, ready for immediate withdrawal on demand.',
          question_5: 'Какие допустимые риски у бота?',
          answer_5:
            'Firstly, Cryptobot got the Money Services Business license issued by FinCEN. Secondly, as one of the biggest brokers in Binance, Cryptobot aggregated the liquidity from Binance and Huobi, and your funds are securely stored in them. Last but not least, Pionex keeps 100% reserve, ready for immediate withdrawal on demand.',
        },
      },
    },
    kmm: {
      header: {
        name: 'Митап по Kotlin Multiplatform Mobile (KMM)',
        title:
          'Задавайте вопросы по вашим кейсам и решайте задачи на KMM вместе с IceRock',
        desc: 'Проводим встречи по средам в 14:00 Мск каждые две недели',
        button: 'Зарегистрироваться',
        modalTitle: 'Запись на meetup',
      },
      whyKotlin: {
        title: 'Почему митапы с  <br/> <span>IceRock</span> — это круто',
        desc_1:
          'Мы, IceRock, — эксперты в разработке приложений на Kotlin Multiplatform Mobile. За 3,5 года работы с технологией наша команда создала несколько десятков приложений под разные потребности клиентов.',
        desc_2:
          'С 2019 года наши специалисты выступают на профильных конференциях вроде KotlinConf и APAC, а еще разрабатывают <a href="https://moko.icerock.dev/" target="_blank">мультиплатформенные библиотеки</a> на Kotlin.',
        desc_3:
          'Практика в решении задач на KMM сделала нас лучшими в консалтинге и разработке — как в России, так и за рубежом. А еще у IceRock есть блоги на  <a href="https://habr.com/ru/users/icerockdev/posts/" target="_blank">«Хабре»</a> и  <a href="https://vc.ru/u/208866-icerock" target="_blank">vc.ru</a>, где мы тоже делимся опытом, в том числе показываем наши кейсы.',
        desc_4:
          'KMM помогает нам решать самые разные задачи, а мы помогаем сообществу программистов познакомиться с этим инструментом и оценить его преимущества на наших кейсах. Митап — один из способов делать это максимально эффективно.',
      },
      howIsItDone: {
        title: 'Как проходит митап',
        fitItem_1: { title: 'Где?', description: 'В Google meet' },
        fitItem_2: {
          title: 'Когда?',
          description: 'Каждые две недели в среду в 14:00 мск',
        },
        fitItem_3: { title: 'Сколько?', description: '1 час' },
        fitItem_4: {
          title: 'С кем?',
          description: 'Эксперт(ы) IceRock и группа из трех-четырех человек',
        },
        fitItem_5: { title: 'Цена', description: 'Бесплатно' },

        graduates: 'Выпускникам факультетов мобильной разработки',
        develiopers:
          'Действующим Junior и Middle программистам на iOS и Android',
        manager: 'Product-менеджерам с навыками программирования и Senior’ам',
      },
      whoShouldGo: {
        title: 'Кому стоит прийти',
        paragraph_1:
          'Всем программистам, которые работают с Kotlin Multiplatform Mobile или только интересуются этой технологией.',
        paragraph_2:
          'Участники наших встреч не только задают вопросы экспертам IceRock, но и вместе ищут варианты решения задач, которые появляются в ходе реальной работы. Поэтому митапы IceRock — это настоящие площадки по обмену опытом.',
        inviteTitle: 'Мы ждем специалистов всех уровней:',
        guestType_1: 'Junior (в KMM)',
        guestType_2: 'Middle',
        guestType_3: 'Senior',
        beginnerTitle: 'Для тех, кто только начинает',
        beginnerParagraph_1:
          'А если вы Junior и мало знакомы с KMM, перед посещением митапа вам могут пригодиться наши стажировки. Там вы погрузитесь в процесс и получите реальные задачи, которые потом захочется обсудить.',
        beginnerParagraph_2:
          'Это тот опыт, который поможет разобраться и двигаться дальше — к новым, еще более интересным проектам!',
        beginnerParagraph_3: 'Стажировка — это:',
        beginnerLi_1: 'Бесплатная практика в крупных проектах',
        beginnerLi_2: '4-8 недель на прокачку полезного навыка',
        beginnerLi_3: 'Задания с повышением уровня сложности и фидбэком',
        beginnerButton: 'Записаться на стажировку',
        beginnerModalTitle: 'Запись на стажировку',
        beginnerSubTitle:
          'Чтобы попасть на стажировку, напишите нам на <a href="mailto:training@icerockdev.com;  qa@icerockdev.com">training@icerockdev.com</a> письмо с темой «Хочу на стажировку!»',
      },
      aboutUs: {
        title:
          'Мы, IceRock, — эксперты в разработке приложений на Kotlin Multiplatform Mobile',
        desc_1:
          'С 2019 года делимся опытом на конференциях вроде KotlinConf и APAC и разрабатываем мультиплатформенные библиотеки на Kotlin.',
        desc_2:
          'Наши клиенты — Leroy Merlin, VEKA, Delivery Club, Marmalato, Золотое Яблоко и другие.',
        button: 'Посмотреть наши кейсы',
      },
      whenToGo: {
        title: 'Когда пора  на MeetUp',
        subtitle:
          'Основные — но не все! — вопросы, в которых можно разобраться на наших встречах:',
        reason_1: 'Не понимаете, с чего начать работать с KMM',
        reason_2: 'Уже начали внедрение, и нужна помощь в расширении KMM',
        reason_3:
          'Видите пользу технологии в работе и хотите извлечь из нее максимум',
        reason_4: 'Оценили KMM, но не получилось донести ценность до команды',
        notice: {
          title: 'Важно!',
          description:
            'Ценность митапов в том, что это не онлайн-лекция — даже на встрече с заданной темой всегда можно обсудить свои вопросы и подискутировать.',
        },
      },
      faq: {
        title: 'FAQ',
        question_1: 'Сколько стоит участие в митапе?',
        answer_1: 'Участие бесплатное.',
        question_2: 'Есть ли критерии отбора участников?',
        answer_2: 'Принять участие в митапе могут все желающие.',
        question_3: 'Сколько человек может участвовать?',
        answer_3:
          'Мы организуем встречи небольшими группами, три-четыре человека.',
        question_4: 'Можно ли прийти группой от компании?',
        answer_4:
          'Да, митап можно провести для специалистов из одной организации.',
        question_5: 'Нужно посещать митапы постоянно или достаточно одного?',
        answer_5:
          'Митап — это как мастер-класс или семинар. На встречах мы рассматриваем конкретные вопросы, кейсы и ситуации. Если есть вопросы или очень интересны темы, приходите сколько угодно. Нашли ответ на одной встрече — тоже супер.',
        question_6: 'У митапа всегда есть тема?',
        answer_6:
          'Мы стараемся задавать вектор встречи заранее, но бывает и иначе. Задавать свои вопросы на другие темы, касающиеся KMM, при этом можно на любой встрече.',
        question_7:
          'Можно ли прийти с другим вопросом по Kotlin Multiplatform Mobile?',
        answer_7: 'Да!',
        question_8:
          'У меня есть вопрос по бэкенду Kotlin, получится обсудить на митапе?',
        answer_8:
          'Сейчас на встречах мы обсуждаем мобильную разработку. В будущем планируем расширять спектр вопросов для дискуссий.',
        question_9: 'Будет ли запись встречи?',
        answer_9:
          'Да, все встречи мы записываем и делимся ссылкой с участниками в течение нескольких дней.',
        question_10:
          'Можно ли прийти с общим вопросом по мобильной разработке?',
        answer_10: 'Да, конечно!',
      },
      ourClients: {
        title: 'К нам приходят из этих компаний',
      },
      footer: {
        date: 'Следующий MeetUp',
        title: 'Среда в 14:00 Мск',
        subtitle:
          'Задавайте вопросы по вашим кейсам и решайте задачи на KMM вместе с IceRock',
        button: 'Зарегистрироваться',
        modalTitle: 'Запись на meetup',
      },
      form: {
        title: 'Запись на онлайн-курс',
        desc: 'Наш менеджер свяжется с вами в ближайшее время',
        name: 'Имя',
        email: 'Email',
        comment: 'Должность (необязательно)',
      },
    },
    kmmPage: {
      kmmHeader: {
        title: 'Integrate Kotlin Multiplatform',
        titleBigger: 'Mobile into your project',
        desc: 'Write the business logic for both platforms once and save your time and money',
        button: 'Contact us',
      },
      kmmIntegrate: {
        title: 'How to use KMM',
        cards: {
          card_1:
            'You made an app or you have just started building it. Your app will be used across two different platforms: Android and iOS.',
          card_2:
            'Your Android and iOS app has complex business logic with a simple UI (e.g. offline synchronization).',
          card_3:
            'You have an Android app with Kotlin, but you need urgently to release an iOS version using recent progress.',
        },
      },
      kmmForWhom: {
        subTitle:
          'In addition to KMM integration, we also support you during the switching to KMM',
        title: 'Who can use it?',
        button: 'Contact us',
        cards: {
          card_1: {
            desc: 'Your developers are aware of this technology and have already tried it',
            title: 'They need our expertise',
            titleExp: 'We have:',
            exp_1: 'Knowledge base',
            exp_2: 'Libraries',
          },
          card_2: {
            desc: 'Your developers have only heard about this technology, but have not tried it yet',
            title: 'They need our training',
            titleExp: 'We can:',
            exp_1: 'Train beginners',
            exp_2: 'Train middles and seniors',
          },
        },
      },
      kmmWhatsGive: {
        title: 'The benefits of integrating KMM',
        cards: {
          card_1: 'You can potentially save up at least 1/3 of your budget',
          card_2:
            'Improving the developers productivity and connecting them to other projects simultaneously',
          card_3:
            'A 25% acceleration of development and the ability to implement a new feature quickly and easily on two platforms at once',
          card_4: 'IOS and Android developers have a common ground',
        },
      },
      kmmTransition: {
        title: 'Switching to KMM',
        button: 'Contact us',
        cases: {
          case_1: {
            desc: 'You have an app on both platforms and you need our developers help to make common business logic',
            steps: [
              'Signing an NDA',
              'Project analysis',
              'The KMM personal integration plan',
              'Project connection',
              'Implementing the business logic into the common code',
            ],
            cards: {
              card_1: {
                name: 'Cost',
                desc: 'starts at $7000 ',
              },
              card_2: {
                name: 'Analysis and plan',
                desc: 'within 2-5 days',
              },
              card_3: {
                name: 'Integration',
                desc: 'between 2 weeks and 6 months, depending on the project',
              },
            },
          },
          case_2: {
            desc: 'Your team has successfully tried KMM and all you need is guidance',
            steps: [
              'Signing an NDA',
              'Project analysis',
              'Personal integration plan with training tools',
              "Following your team's plan",
              'Every step of the integration is reviewed',
            ],
            cards: {
              card_1: {
                name: 'Cost',
                desc: 'starts at $1400',
              },
              card_2: {
                name: 'Analysis and plan',
                desc: 'within 2-5 days',
              },
            },
          },
          case_3: {
            desc: 'You have an Android app and you urgently need to release an iOS version and keep development going. And then combine the business logic of both apps',
            steps: [
              'Signing an NDA',
              'Project analysis',
              'The KMM personal integration plan',
              'Creating a common code for the second platform with the ability to integrate into the first platform',
              'Integration of common code into the first platform',
            ],
            cards: {
              card_1: {
                name: 'Cost',
                desc: 'starts at $14000',
              },
              card_2: {
                name: 'Analysis and plan',
                desc: 'within 2-5 days',
              },
              card_3: {
                name: 'Common Code App',
                desc: 'between 1 and 6 months',
              },
              card_4: {
                name: 'Integration into the first platform',
                desc: 'from 2 weeks to several months',
              },
            },
          },
        },
      },
      kmmWeDidIt: {
        title: 'The results of our work',
        cases: {
          case_1: {
            title: 'Getmega',
            subTitle: 'getmega.com',
            desc: "An app for online cooperative games and communication. Available on iOS and Android. <br /><br /><strong>The result</strong>: we created a common code which integrates with the iOS app. The common code is based on the Android version, which was already written in Kotlin. This gave the client two apps with common logic, without interrupting work on the Android app's functionality.",

            cards: {
              card_1: {
                name: 'Analysis',
                desc: '3 days',
              },
              card_2: {
                name: 'Development',
                desc: '3 months',
              },
              card_3: {
                name: 'Cost',
                desc: '> $40000',
              },
            },
          },
          case_2: {
            title: 'Footballco',
            subTitle: 'footballco.com',
            desc: 'Streaming service for football. Available on iOS and Android. <br /><br /><strong>The result</strong>: we conducted an audit. They made the common logic themselves and connected it to their Android- and iOS-apps. Then they came to us to get an expertise.',

            cards: {
              card_1: {
                name: 'Conduct an audit',
                desc: '3 days',
              },
              card_2: {
                name: 'Cost',
                desc: '$1000',
              },
            },
          },
          case_3: {
            title: 'profi',
            subTitle: 'profi.ru',
            desc: 'An app for searching for professionals in different fields. Available on iOS and Android. <br /><br /><strong>The result</strong>: we helped to transfer the business logic from the Android app to the multiplatform. They described this process in details <a href="https://habr.com/ru/post/556318/" target="_blank" rel="noreferrer">on Habr.com</a> in step 3.',

            cards: {
              card_1: {
                name: 'Analysis and plan',
                desc: '2 days',
              },
              card_2: {
                name: 'Review',
                desc: 'couple weeks',
              },
            },
          },
        },
      },
      kmmForm: {
        title:
          'Contact us. And we will consult you for free on how an app can make your project better.',
      },
    },
    iot: {
      title: {
        title:
          'Development of iOS and Android Apps to Control Cars, Smart Homes, and Household Appliances',
        desc: 'Make your product more user-friendly, modern, and profitable',
      },
      solutions: {
        title: 'Our Solutions for Real-World Businesses',
        tab_0: {
          title: 'Cars',
          desc:
            '<p>Element is our smart system that enables cars to be controlled remotely. It helps you monitor a car’s health, save on car insurance and gas, and collect statistics on your driving style.</p>' +
            '<p>Element consists of a device installed in your car together with an app that allows the driver to keep track of the car’s health.</p>' +
            '<p>Made for Smart Driving Lab. The app runs on iOS and Android.</p>',
          features: {
            0: 'Auto-start',
            1: 'Lighting control',
            2: 'Engine preheater control',
            3: 'Trunk opening/closing',
            4: 'Viewing statistics on driving and fuel consumption',
          },
        },
        tab_1: {
          title: 'Smart Home',
          desc:
            '<p>NEVOTON AUTOMATICA is a mobile app for remote control of smart home devices.</p>' +
            '<p>The user can set up schedules for their devices, adjust the temperature and humidity, and assign operation scenarios to turn on heating, lighting, and other devices at the same time. It takes a single button press to activate these scenarios.</p>' +
            '<p>Made for NEVOTON. The app runs on iOS and Android.</p>',
          features: {
            0: 'Lighting, heating, and sauna or swimming pool equipment control',
            1: 'Remote start-up',
            2: 'Setting up start-up scenarios',
            3: 'Selecting working parameters for appliances',
            4: 'Status review using sensor and camera data',
          },
        },
        tab_2: {
          title: 'Household Appliances',
          desc:
            '<p>My Fridge is a new payment solution for vending machines.</p>' +
            '<p>No need to carry cash or enter item numbers on a keypad to make a purchase from a customer’s refrigerated vending machine. Instead, the user scans a QR code on the fridge in the app, gets a product list, and buys the item they want using a payment card.</p>' +
            '<p>Made for Belaya Sfera. The app runs on iOS and Android.</p>',
          features: {
            0: 'QR code scanning for device identification',
            1: 'Auto-loading of a list of products taken from the fridge',
            2: 'Payment using a linked bank card',
            3: 'Purchase history',
            4: 'Digital receipt',
          },
        },
      },
      principles: {
        title: 'Our Principles',
        desc: 'At IceRock, we follow several principles when developing our apps. They help us create quality products that truly meet our customers’ needs — even when we have to think outside the box.',
        0: {
          title: 'We use secure protocols',
          desc: 'for data transfer and fully test our apps to protect customers from malicious activities and keep your reputation spotless.',
        },
        1: {
          title: 'We cover responsibility for missed deadlines',
          desc: 'in our agreement, so you can plan your internal and external product launch operations without any doubts.',
        },
        2: {
          title: 'We use',
          desc: 'Kotlin Multiplatform Mobile, so you spend less time and money on app revisions and updates.',
        },
        3: {
          title: 'We provide quick and viable prototyping',
          desc: 'covering the key functions in a short time frame. Learn more — <a href="https://prototyping.icerockdev.com/" target="_blank">here.</a>',
        },
        4: {
          title: 'We study our competition and target audience needs',
          desc: 'so your app stands out from the crowd.',
        },
        5: {
          title:
            'We develop business logic for both iOS and Android from the get-go',
          desc: 'you won’t have to wait for logic to be developed for the other platform or pay for each platform separately.',
        },
        6: {
          title: 'We leverage our experience',
          desc: 'in the IoT market: we are happy to prove our expertise and can guarantee high quality standard of your app.',
        },
      },
      whenOrder: {
        title: 'When is the best time to order an app?',
        cards: {
          0: 'At the concept stage — order an MVP and test it',
          1: 'When you’ve decided to create the app — and all that’s left is to implement it',
          2: 'When the app already exists but needs updating and upgrading',
        },
        features: {
          0: { bold: '4 months', text: 'from start to release' },
          1: { bold: 'From $ 25 000', text: 'baseline cost' },
          2: { bold: '2–4 months', text: 'warranty' },
        },
      },
      form: {
        title: 'Make a request',
        name: 'Name',
        agree: {
          text: 'By clicking the SEND button, you consent to the processing of your',
          link: 'personal data',
        },
        button: 'Submit',
      },
      whyUs: {
        title: 'Why IceRock?',
        desc: 'Here at IceRock, we are experts in developing IoT applications using Kotlin Multiplatform Mobile. KMM helps us tackle a wide variety of tasks: in our six years in business, we have implemented over 60 large projects.',
        text: 'We’ve been one of the highest-ranked mobile app developers since 2017, and in 2020 IceRock was named one of the top B2B contractors in Russia.',
      },
      faq: {
        title: 'FAQ',
        titleMobile: 'Frequently Asked Questions',
        0: {
          title: 'Can you add new features to our app?',
          desc: 'We always work on a case-by-case basis and offer an optimal solution for any given situation. We can update your app or develop a new one, depending on what would work best for you.',
        },
        1: {
          title: 'Do you offer tech support for the app?',
          desc: 'Yes, and we independently track user feedback and offer updates and fixes.',
        },
        2: {
          title:
            'What does the warranty period depend on, and what does it cover?',
          desc: 'The warranty period depends on the nature and scope of the work. We discuss all that beforehand and formalize the conclusions in the contract. The warranty includes free-of-charge fixes for potential bugs that might appear when the app is used.',
        },
        3: {
          title: 'How do you integrate the app with our hardware?',
          desc: 'You provide us with the API, as well as with the hardware or the product you need the app for, and we test its functionality and develop the app specifically for it.',
        },
        4: {
          title: 'Can you integrate the app with other services?',
          desc: 'Yes. For instance, we can set up intruder alerts for security or the police. To do this, we would need data transfer protocols and similar external solutions.',
        },
        5: {
          title: 'Will the app automatically adapt to new products?',
          desc: 'We write very flexible code, but it might need updating for entirely new products. There is a separate charge for this.',
        },
        6: {
          title: 'Do you participate in tenders?',
          desc: 'We certainly do.',
        },
      },
    },
    finance: {
      header: {
        title:
          'Development of iOS and Android Applications for Internal Banking Processes and Investments',
        desc: 'Helping banks optimize their operations and serve individuals and businesses online',
        phone: '+37494044318',
        email: 'mobiledev@icerockdev.com',
        button: 'Send a request',
      },
      solutions: {
        title: 'Our Solutions for Actual Businesses',
        desc: 'We have experience implementing a variety of projects for the banking industry',
        cards: [
          { title: 'Investment management apps' },
          { title: 'Banking products apps' },
        ],
        linkName: 'Detail information',
        tab_0: {
          title: 'Upstox',
          desc: 'Upstox is a mobile app for investing and trading.  It runs on iOS and Android.',
          features: [
            { title: 'Dynamic information sections' },
            { title: 'Stories' },
            { title: 'Real-time statistics' },
            { title: 'Stock exchange ratings' },
            { title: 'Integrated educational and news content' },
            { title: 'Quotes' },
          ],
        },
        tab_1: {
          title: 'SBI-bank',
          desc: 'SBI is a family-oriented bank. It runs on iOS and Android.',
          features: [
            { title: 'Access to accounts from mobile devices' },
            { title: 'Cashback' },
            { title: 'Setting spending limits' },
            { title: 'Gamification system' },
            { title: 'Complex architecture* ' },
          ],
          note: '*Each function is divided into business and UI modules',
        },
        tab_2: {
          title: 'VTB Quorum',
          desc: 'VTB Quorum is an app for voting at VTB events. It runs on the iPad, written in Swift.',
          features: [
            { title: 'Oral voting at events' },
            { title: 'User identification by database' },
            { title: 'NFC tags' },
            { title: 'Voice recognition technology' },
            { title: 'Reading a question using Siri' },
          ],
        },
        tab_3: {
          title: 'Register MFO',
          desc: 'Registry MFO is a registry of microcredit organizations. It runs on Android and iOS.',
          features: [
            { title: 'Unified design for both platforms' },
            { title: 'Data registry' },
            { title: 'The same source code for two applications' },
            { title: 'Fast searching*' },
          ],
          note: '*Required microcredit organization and tracking its status',
        },
        tab_4: {
          title: 'Cashier',
          desc: "Cashier is an app that generates unique id's for rapid money transfers. It runs on Android and iOS.",
          features: [
            { title: 'Money transfers' },
            { title: 'Data encryption' },
            { title: 'Integration with Android SDK' },
            { title: 'Operations statistics' },
          ],
        },
      },
      developBank: {
        title: 'Developing Mobile Applications to Specification for Banks',
        steps: [
          'Enter the market with a new product',
          'Update your current application',
          'Create your own app instead of a template solution',
          'Increase customer loyalty and reduce churn',
          'Gain a competitive advantage',
          'Develop a banking system for legal entities',
        ],
      },
      developApp: {
        title: 'Creating Apps from Scratch',
        desc: 'We can help you create a high-quality, user-friendly, easy-to-understand product that will engage your audience',
        steps: [
          {
            title: 'Preparation',
            list: [
              'Analyze competitor apps',
              'Analyze the previous app',
              'Engage in customer development and create a CJM',
              'Do an audit and develop app requirements',
            ],
          },
          {
            title: 'Development',
            list: [
              'Discuss terms of reference in detail',
              'Develop prototypes',
              'Set milestones',
              'Create a design in the corporate style',
              'Build a backlog',
              'Discuss the results of each stage',
            ],
          },
          {
            title: 'Testing',
            list: [
              'Test the prototype on a real closed audience segment',
              'If necessary, refine the app',
              'Plan further development',
            ],
          },
        ],
      },
      finalizeApp: {
        title: 'Refining Your App',
        desc: 'We can optimize your existing app',
        steps: [
          'Make the app easier to use and add new functions.',
          'Analyze its performance and reduce support and development costs.',
          'Create additional integrations with existing software solutions.',
        ],
      },
      mvp: {
        title: 'Building MVP Prototypes for Banks',
        desc: 'We will create a product with just enough functions to test the hypothesis. Based on the results, you will be able to make a decision on whether to take development further.',
        offer: {
          title: 'Our offer',
          desc: 'We will suggest an option allowing for a quick start with minimal investment of money and time',
          price: { title: 'Price:', content: '$ 18 000+' },
          time: { title: 'Time frame:', content: '3 months+' },
        },
      },
      promises: {
        card_1: {
          title: '<span>Setting Up</span> Required Functions',
          primaryList: [
            'Transfers and payments',
            'Investment management',
            'Account and financial management',
          ],
          list: [
            'Registering new products without visiting the office',
            'Forming and signing payment orders',
            'Taxes, fines, utility and mobile payments',
          ],
        },
        card_2: {
          title: '<span>Creating</span> a Secure Product Users Will Trust',
          desc: 'We use modern security approaches in our work:',
          primaryList: [
            'HTTPS',
            'Data encryption',
            'Role-based access control',
          ],
          list: [
            'Two-factor authentication',
            'The Face ID facial recognition system',
            'The Touch ID fingerprint scanner',
            'External audits by data security experts',
            'Storage of personal data in accordance with legal requirements',
          ],
        },
      },
      reviewsSlider: {
        title: 'What Customers Say about Us',
        button: 'All reviews',
      },
      kmm: {
        title:
          'Speeding Up Development with Our Expertise in Kotlin Multiplatform',
        desc: 'We use Kotlin Multiplatform, which allows us to write business logic for two platforms at once. This reduces development time and saves you money.',
        cards: [
          'Optimize development using shared code for iOS and Android',
          'Reduce development time and launch your product on time',
        ],
        developTime: 'Standard time frame: <span>~4 months</span>',
      },
      guarantees: {
        title: 'Guarantees Provided',
        cards: [
          'Free error correction*',
          'An app guaranteed to fully correspond to your terms of reference',
        ],
        desc: '*3- to 6-month warranty depending on the project',
      },
      stayWithYou: {
        title: '<span>We Stay with You</span> after the Project Launch',
        desc: 'We can participate in the long-term development and support of your product',
        list: [
          'Creating a product development roadmap together',
          'Prioritizing features',
          'Devising a release map',
          'Starting development',
          'Implementing new features as the project grows',
        ],
      },
      form: {
        title: 'I want the same app',
        name: 'Имя',
        agree: {
          text: 'Нажимая кнопку «ОТПРАВИТЬ», вы даете согласие на обработку своих',
          link: 'персональных данных',
        },
        button: 'Отправить',
      },
      faq: {
        title: 'FAQ',
        titleMobile: 'Frequently Asked Questions',
        0: {
          title: 'Can you set up integration with existing software systems?',
          desc: 'Yes, we can set up integration with other products using REST, SOAP or WebServices.',
        },
        1: {
          title: 'How much does it cost to develop an application?',
          desc: 'The price depends on the complexity and scope of the project. An MVP will cost you $ 18 000+, a small app $ 36 000+, and a large banking application $ 90 000+. These prices are for the development of an application for a single platform.',
        },
        2: {
          title:
            'Can you prepare prototypes and design samples for a few screens?',
          desc: 'Yes, after discussing the terms of reference we will prepare prototypes and design samples in your corporate style.',
        },
        3: {
          title: 'Do you participate in tenders?',
          desc: 'Yes, we do. Fill in the contact form below to send us an invitation to tender.',
        },
        4: {
          title: 'Can you refine our backend?',
          desc: 'Yes, if it’s on Kotlin.',
        },
      },
    },
    cource: {
      header: {
        advantages: 'Преимущества курса',
        aboutUS: 'О нас',
        program: 'Программа курса',
        faq: 'FAQ',
        name: 'Kotlin Multiplatform Mobile',
        title:
          'Онлайн-курс по новой технологии для программистов на Android и iOS.',
        desc: 'Научитесь работать с кросс-платформенным SDK прямо во время работы или учебы и начните зарабатывать больше с IceRock.',
        button: 'Записаться на курс',
      },
      whyKotlin: {
        title: 'Почему именно <br/><span>Kotlin</span>',
        desc_1:
          'Раньше одно и то же приложение приходилось писать отдельно под каждую операционную систему. При этом некоторые части кода были одинаковыми для разных платформ, но их все равно требовалось прописывать заново.',
        desc_2:
          'Kotlin Multiplatform Mobile (KMM) — это SDK, который позволяет создать код сразу для всех ОС и оставить возможность для изменений там, где это необходимо. Это экономит время и бюджет, поэтому программист со знанием Kotlin круче, чем просто хороший мобильный разработчик.',
        willDo: 'Этот курс подойдет',
        graduates: 'Выпускникам факультетов мобильной разработки',
        develiopers:
          'Действующим Junior и Middle программистам на iOS и Android',
        manager: 'Product-менеджерам с навыками программирования и Senior’ам',
      },
      whyIcerock: {
        title: 'Почему курс от IceRock',
        cause_1_title: 'Бесплатное обучение',
        cause_1_desc:
          'Мы сделали наш курс бесплатным, чтобы ничто не мешало вам повысить свой уровень и, возможно, стать частью нашей команды.',
        cause_2_title: 'Легко совмещать',
        cause_2_desc:
          'Все уроки уже записаны, вебинаров нет. Вы сами решаете, когда, где и сколько учиться — удобно, если работаете или получаете образование.',
        cause_3_title: 'Комфортный темп',
        cause_3_desc:
          'Вы можете закончить курс за неделю или за год — мы не требуем соблюдать дедлайны. Единственное, что влияет на сроки обучения, это ваши собственные возможности.',
        cause_4_title: 'Русский язык',
        cause_4_desc:
          'Многие программисты владеют английским, на котором написано большинство профильных курсов. Но с инструкциями на родном языке дело пойдёт ещё быстрее.',
        cause_5_title: 'Обширная библиотека',
        cause_5_desc:
          'Курс дополнен всеми необходимыми материалами, пошаговыми инструкциями с иллюстрациями и ссылками на скачивание ПО. Не нужно тратить время на поиски.',
        cause_6_title: 'Лучшим — работа сразу',
        cause_6_desc:
          'Мы не выдаем красный диплом. Вместо этого выпускников, достигших хорошего уровня в Kotlin, приглашаем к нам в команду сразу после обучения.',
      },
      aboutUs: {
        title:
          'Мы, IceRock, — эксперты в разработке приложений на Kotlin Multiplatform Mobile',
        desc_1:
          'С 2019 года делимся опытом на конференциях вроде KotlinConf и APAC и разрабатываем мультиплатформенные библиотеки на Kotlin.',
        desc_2:
          'Наши клиенты — Leroy Merlin, VEKA, Delivery Club, Marmalato, Золотое Яблоко и другие.',
        button: 'Посмотреть наши кейсы',
      },
      steps: {
        title: 'Курс состоит из 12 этапов',
        step_1: 'Знакомство с KMM',
        step_2: 'Запуск проекта',
        step_3: 'Устройство проекта',
        step_4: 'MOKO библиотеки',
        step_5: 'Делаем экран авторизации',
        step_6: 'Делаем список',
        step_7: 'Работа с сетью',
        step_8: 'Делаем профиль',
        step_9: 'Добавляем постраничную загрузку',
        step_10: 'Добавляем push уведомления',
        step_11: 'Добавляем отображение элементов на карте',
        step_12: 'Итоговый тест',
        button: 'Записаться на курс',
      },
      whatAwaits: {
        title: 'Что вас ждёт в конце курса',
        await_1_title: 'Новый уровень в разработке',
        await_1_desc:
          'Расширите технический стек и сможете выполнять больше задач быстро и качественно — а значит, перейдете на следующую профессиональную ступень.',
        await_2_title: 'Практический опыт',
        await_2_desc:
          'Во время курса поймете принципы работы и набьете руку на заданиях по программированию, чтобы уверенно применять знания в работе. ',
        await_3_title: 'Возможность претендовать на более высокий гонорар',
        await_3_desc:
          'Технология KMM экономит бизнесу время и деньги, а значит, труд специалиста с этим языком в портфеле ценится выше.',
        await_4_title: 'Новые интересные проекты',
        await_4_desc:
          'Там, где раньше вы не были уверены в своих силах, теперь будете чувствовать себя в своей стихии и сможете пополнить портфолио еще более крутыми кейсами.',
        await_5_title: 'Более эффективный менеджмент',
        await_5_desc:
          'Поймете принципы кроссплатформенной разработки, сможете упростить поддержание проекта и сделать продукт стабильнее без лишних временных и профессиональных затрат.',
      },
      footer: {
        copywriter: '© IceRockDevelopment 2021',
      },
      faq: {
        title: 'FAQ',
        question_1: 'Сколько стоит обучение?',
        answer_1: 'Курс в открытом доступе, обучение бесплатное.',
        question_2:
          'Я только окончил вуз или пока учусь и у меня еще нет практики, мне подойдет этот курс?',
        answer_2:
          'Да. Если вы не полный новичок в программировании, вам все будет понятно.',
        question_3: 'Сколько длится курс?',
        answer_3: 'Это зависит только от вас: мы не ставим сроки.',
        question_4: 'Какое ПО мне понадобится для обучения?',
        answer_4:
          'Потребуется установить актуальную версию Android Studio. Рекомендуем предварительно изучить системные требования программы.',
        question_5:
          'Это запись, или обучение будет вестись онлайн в заранее запланированное время?',
        answer_5:
          'Все уроки на платформе записаны заранее, поэтому вы можете заниматься в удобное время.',
        question_6:
          'Будет ли обратная связь от преподавателей курса по практическим вопросам?',
        answer_6:
          'Мы будем рады всем неточностям и дополнениям к этому курсу, а также ошибкам, если вдруг какая-то закралась, а с заданиями справляться мы предлагаем самостоятельно :)',
        question_7: 'Будет ли выдаваться какой-то диплом?',
        answer_7:
          'Диплома не будет, но будут знания и возможность устроиться на классную работу.',
        question_8:
          'Будет ли возможность трудоустройства после прохождения курса?',
        answer_8:
          'Лучших выпускников мы приглашаем в нашу команду. Остальные смогут включить навык программирования на Kotlin в резюме — уверены, работодатели оценят. ',
      },
      form: {
        title: 'Запись на онлайн-курс',
        desc: 'Наш менеджер свяжется с вами в ближайшее время',
        name: 'Имя',
        email: 'Email',
        comment: 'Должность (необязательно)',
      },
    },
    internship: {
      title: {
        title: ' Internship from <span>IceRock</span> Development',
        desc: 'Learn mobile development on Android and iOS and start working in IT right after your studies',
        button: 'Detail information',
      },
      features: {
        0: '<span>6 лет опыта</span> мобильной разработки и 60+ приложений',
        1: 'Получаем заказы со всей России и за рубежом: <span>США, Европа</span> и другие',
        2: '<span>Делимся своим опытом</span> на конференциях, в статьях на Хабре и в кейсах в нашем блоге',
      },
      cases: {
        title: 'Над чем будете работать',
        subtitle: 'Вот что наши специалисты делают для клиентов',
        link: 'Посмотреть все кейсы',
      },
      whatYouGet: {
        title: 'Что вы получите от стажировки',
        0: 'Обучение с нуля или продолжение обучения',
        1: '4-8 недель на прокачку полезного навыка',
        2: 'Задания для отработки теории: приложение по подробному ТЗ, свой проект или участие в боевом проекте — в зависимости от варианта стажировки',
        3: 'Подробный фидбек от наставника',
        4: 'Предложение работы лучшим студентам и тем, кто проходит вариант «Корпоративный университет IceRock»',
        5: 'Оплата стажировки тем, кто проходит вариант «Корпоративный университет IceRock»',
      },
      whatYouGetWithoutCorpEdu: {
        title: 'Что вы получите от стажировки',
        0: 'Обучение с нуля или продолжение обучения',
        1: '4-8 недель на прокачку полезного навыка',
        2: 'Задания для отработки теории: приложение по подробному ТЗ, свой проект или участие в боевом проекте — в зависимости от варианта стажировки',
        3: 'Подробный фидбек от наставника',
        4: 'Предложение работы лучшим студентам',
      },
      options: {
        title: '3 варианта стажировки',
        subtitle:
          'Мы открыты всем мобильным разработчикам на Android и iOS, поэтому создали несколько вариантов стажировки в зависимости от вашего уровня.',
        0: {
          title: 'Стажировка для разработчиков с нуля',
          subtitle: 'Что ждет на стажировке',
          0: 'Выдадим базовый список материалов для изучения',
          1: 'Вы будете самостоятельно разрабатывать Android-приложение «Заметки» по выданным требованиям',
          2: 'У вас будет возможность видеовстречи для более детального обсуждения фидбека и обозначения курса для дальнейшего движения',
          3: 'Вы получите ревью своей работы на нашем github.com с пометками, что стоит поправить, что почитать, как изменять',
          specialOne: {
            title: 'Оплата стажировки',
            subtitle: 'не предусмотрена, участие бесплатное',
          },
          specialTwo: {
            title: 'Трудоустройство',
            subtitle: 'да, лучшим студентам после окончания стажировки',
          },
        },
        1: {
          title: 'Преддипломная практика',
          subtitle: 'Что ждет на стажировке',
          0: 'Выдадим базовый список материалов для изучения',
          1: 'Выделим наставника из числа сотрудников компании',
          2: 'С установленной периодичностью наставник будет отвечать на вопросы и делать ревью',
          3: 'С установленной периодичностью будут проводиться видеовстречи с наставником, где можно решить вопросы голосом или расшарив друг другу экран',
          4: 'При необходимости поможем с выбором темы дипломной работы',
          5: 'Примерно обозначаем, как можно решить задачу по диплому (предложим библиотеки, архитектуры, которые можно взять и т. п.)',
          6: 'Поможем с несложным дизайном, чтобы были графические ресурсы к выполнению задачи',
          specialOne: {
            title: 'Оплата стажировки',
            subtitle: 'не предусмотрена, участие бесплатное',
          },
          specialTwo: {
            title: 'Трудоустройство ',
            subtitle: 'да, лучшим студентам после окончания стажировки',
          },
        },
        2: {
          title: 'Корпоративный университет IceRock',
          desc: 'Для тех, у кого есть база. Количество мест ограничено',
          subtitle: 'Что ждет на стажировке',
          0: 'Полная занятость',
          1: 'Обучением занимается лично технический директор IceRock: <a href="https://github.com/alex009" target="_blank">https://github.com/alex009</a>',
          2: 'Обучающий курс включает в себя:  <br/> 1. Разработка под android (базовый уровень). <br/> 2. Разработка под iOS (базовый уровень). <br/> 3. Разработка с использованием Kotlin Multiplatform Mobile. <br/> 4. Выпускная работа — реализация проекта приближенного к продакшен уровню в реальных рабочих процессах и инструментах.',
          3: 'После завершения обучения разработчик попадает в одну из команд разработки, где продолжает развиваться, работая на проектах заказчика',
          specialOne: {
            title: 'Оплата стажировки',
            subtitle: 'да, полноценная зарплата junior-разработчика',
          },
          specialTwo: {
            title: 'Трудоустройство',
            subtitle:
              'да, с первого дня стажировки официальное трудоустройство в компанию',
          },
        },
      },
      benefits: {
        title: 'Преимущества работы в IceRock',
        0: '<span>Гибкий</span> график.',
        1: '<span>Заказчики из разных стран</span>: России, США, Европы и других. ',
        2: 'Работа в следующих <span>направлениях разработки</span>: Telecom, Bookings, Payments, Healthcare, Internet of Things, M-commerce, Delivery, Social и Location-based.',
        3: 'Работа над <span>полезными продуктами</span> с использованием разнообразных технологий. ',
        4: '<span>Индивидуальный план развития</span> и 2 оплачиваемых часа в неделю в рабочее время на обучение. А также возможность обсудить тему и поделиться своими знаниями на внутренних митапах.',
        5: '<span>Свобода для творчества и индивидуальное признание.</span> Мы ценим в сотрудниках креативность и находчивость. Любим, когда специалист не только следует инструкции, но и предлагает свои идеи. Каждый год подводим итоги и награждаем лучших. ',
        6: '<span>Теплый и дружный коллектив</span>, с которым будете играть во время отдыха в «Мафию», «Каркассон» или даже в «Ужас Аркхэма». ',
        7: '<span>Просторный офис с современной техникой</span> в центре Новосибирска или удаленная работа.',
      },
      form: {
        title: 'На какую стажировку хотите попасть?',
        name: 'Ваше имя',
        email: 'Ваш e-mail',
        chooseOption: 'Выберите стажировку',
        button: 'Хочу стажироваться в IceRock!',
        textarea: 'Расскажите про свой опыт в программировании',
      },
    },
    cdto: {
      header: {
        title:
          'IceRock Development: An Established Professional Team for Business Digitalization',
        desc: 'We’ll help you automate manual work and make production more efficient and economical',
        phone: '+37494044318',
        email: 'mobiledev@icerockdev.com',
        button: 'Send a request',
      },
      pros: {
        title: 'Benefits of digitalization:',
        list: [
          'Reduces human factors in production',
          'Reduces production costs through the use of big data',
          'Reduces the number of product “rework” cycles and quality control costs',
        ],
      },
      solutions: {
        title: 'Experience',
        part_0: {
          title: 'We have experience in the following fields',
          list: [
            {
              title: 'Production',
              features: [
                'Automation of production processes',
                'Automation of reporting',
                'Automation of application submission and processing',
              ],
            },
            {
              title: 'HR Tech',
              features: [
                'Training new employees in production processes',
                'Automatic testing of employees',
              ],
            },
            {
              title: 'Fintech',
              features: [
                'Integration of banking services',
                'Connection of partner banks to retail stores',
                'Operational security',
              ],
            },
          ],
        },
        part_1: {
          title: 'Our portfolio includes projects for major corporations',
          list: [
            '/images/directions/iiko.svg',
            '/images/directions/iiko.svg',
            '/images/directions/iiko.svg',
          ],
        },
      },
      ourwork: {
        linkName: 'More info',
        tab_0: {
          title: 'Digital Assistant',
          desc_0:
            'Gives operators real-time recommendations (what needs to be added to an alloy to achieve the optimal result) using physicochemical and ML models.',
          desc_1:
            'Helps the company to save on ferroalloys and reduce the number of production cycles.',
          features: [
            { title: 'Apache Kafka' },
            { title: 'Kotlin' },
            { title: 'REST API' },
            { title: 'Adapter-based integration' },
            { title: 'Java' },
          ],
        },
        tab_1: {
          title: 'Leroy Merlin',
          desc_0:
            'A mobile training app with an admin panel for a retail chain. Employees can use the app to take tests that assess how well they understand their workflow. The testing process is monitored by their mentors and supervisors.',
          desc_1:
            'They can check tests, create new ones and track statistics using the admin panel. This enables them to assess how well employees understand the workflow.',
          features: [
            { title: 'RxJava2' },
            { title: 'Retrofit + OkHttp' },
            { title: 'Java' },
            { title: 'Dagger 2' },
            { title: 'Fabric, FCM' },
          ],
        },
        tab_2: {
          title: 'Cashier',
          desc_0:
            'A mobile app that generates unique IDs for instant money transfers. The seller specifies a transaction name and amount in the app.',
          desc_1:
            'The app then generates a unique transaction ID for the purchase. Once the buyer has paid, the seller can see the status of the payment.',
          features: [
            { title: 'Kotlin Multiplatform Mobile' },
            { title: 'SQLDelight' },
          ],
        },
        tab_3: {
          title: 'VTB Voting',
          desc_0:
            'An app for voting at VTB events. We improved a query used to identify users in the database.',
          desc_1:
            'We also added a voice module based on the native iOS framework “Speech” that supports Siri.',
          features: [
            { title: 'Native iOS framework “Speech”' },
            { title: 'iOS in Swift' },
          ],
        },
      },
      howWeWork: {
        title: 'How we work',
        steps: [
          'Making an introductory call, discussing requirements and options',
          'Signing a contract and non-disclosure agreement',
          'Preparing analytics and designing solution architecture',
          'Implementing the project: infrastructure, issue tracker, sprints, evaluation, development',
          'Development',
          'Shipping the latest sprint',
        ],
      },
      ourStack: {
        title: 'Our specialists & Technology stack',
        cards: [
          {
            iconUrl: ['/images/directions/cdto/analytics.svg'],
            title: 'Analytics',
            specialists: '3 specialists',
            body: 'Vision, Use Case, User Story, UML 2.0, BPMN 2.0, DFD, ER diagrams, API specification',
          },
          {
            iconUrl: ['/images/directions/cdto/design.svg'],
            title: 'Design & UI/UX',
            specialists: '5 specialists',
            body: 'Figma, Sketch, Adobe XD, CJM, JTBD, Persona frameworks platform guidelines: Material Design and HIG',
          },
          {
            iconUrl: ['/images/directions/cdto/prototype.svg'],
            title: 'Prototyping',
            specialists: '2 specialists',
            body: 'ProtoPie, Figma, Axure RP, CJM, JTBD, Persona frameworks',
          },
          {
            iconUrl: ['/images/directions/cdto/frontend.svg'],
            title: 'Frontend',
            specialists: '7 specialists',
            body: 'React.js and Vue.js',
          },
          {
            iconUrl: ['/images/directions/cdto/backend.svg'],
            title: 'Backend',
            specialists: '12 specialists',
            body: 'Kotlin, PHP, Java, Spring Boot, Ktor + Exposed + our own open-source libraries, Yii 2, PostgreSQL, MongoDB, Redis',
          },
          {
            iconUrl: [
              '/images/directions/cdto/ios.svg',
              '/images/directions/cdto/android.svg',
            ],
            title: 'Mobile App Development',
            specialists: '29 specialists',
            body: 'Java, Kotlin, Android OS 5.0+, Kotlin Native and Kotlin Multiplatform Mobile, Objective-C, Swift, iOS 12+',
          },
        ],
      },
      price: {
        title: 'How the price is calculated',
        desc: 'The price is calculated based on each specialist’s hourly rate, the number of specialists involved and the expected workload.',
        offer: {
          title: 'Our offer',
          desc: 'Please tell us about your project, and we’ll get back to you about the price. It’s free',
        },
      },
      reviewsSlider: {
        title: 'What Customers Say About Us',
        button: 'All reviews',
      },
      form: {
        title: 'I want an app like that',
      },
      faq: {
        title: 'FAQ',
        titleMobile: 'Frequently Asked Questions',
        0: {
          title: 'Can you set up integration with existing software systems?',
          desc: 'Yes, we can set up integration with other products using REST, SOAP or WebServices.',
        },
        1: {
          title: 'Can you redesign our backend?',
          desc: 'Yes, if it is in Kotlin, Java or PHP.',
        },
        2: {
          title: 'How quickly can you get on the job?',
          desc: 'Within a minimum of two weeks, if our developers are not working on a large project. Analysts can usually get started faster.',
        },
        3: {
          title: 'How much does it cost to develop an application?',
          desc: 'The price is calculated based on each specialist’s hourly rate, the number of specialists involved and the expected workload. Please write to us to discuss your project.',
        },
        4: {
          title: 'Can you develop apps? Do design? Test a project?',
          desc: 'Yes, we can take care of the entire development cycle. Our team includes iOS and Android app developers, QA specialists, designers, and UI/UX specialists.',
        },
        5: {
          title: 'What is your warranty policy?',
          desc: 'Our warranty is stipulated in our contracts and provided on a fixed-price basis. If we allocate a developer or a group to collaborate with a remote team, we work on a time and material basis. In this case, we do not usually provide a warranty.',
        },
      },
    },
    presentation: {
      modal_title: "We can send the case's presentation to your email",
      get: 'Download the presentation',
      success_title: 'Thank you for your request!',
      success_text: 'We will send you the presentation as soon as possible',
      succes_ok: 'Ok',
    },
  },
  forms: {
    email: 'Email',
    phone: 'Phone',
    name: 'Name',
    field_required: 'Please, fill this field',
    fill_all_fields: 'Please, fill all required fields',
  },
  form: {
    email: 'Email',
    phone: 'Phone',
    name: 'Name',
    field_required: 'Please, fill this field',
    fill_all_fields: 'Please, fill all required fields',
  },
  form_vacancy: {
    name: 'Name*',
    email: 'Email*',
  },
  case: {
    task: 'Task',
    solution: 'Solution',
    process: 'Development Process',
    hardest_part: 'The Hardest Part',
    how_we_solved: 'How we solved these tasks',
    conclusions: 'Our conclusions',
    technology: 'Technology Stack',
    mobile: 'Mobile app',
    customer_feedback: 'Customer direct speech',
    results: 'Results',
    discuss: 'Let’s discuss your project!',
    we_will_tell:
      'It is free. We will tell you how the application will solve your problems.',
    leave_a_request: 'Fill in application for consultation',
    similar_projects: 'Similar projects',
  },
  in_develop: {
    process: 'The case is being described',
    come_back: 'Come back in a couple of days',
  },
  speeches: {
    title: 'Speeches',
    seo: {
      title: 'Videos of public speaking of IceRock at conferences',
      description:
        'Videos of public speaking of IceRock at conferences | IceRock Development',
      keywords:
        'icerock, app development, public speeches, conferences, videos, mobile, kotlin, ceo, cto',
    },
    description: 'Speeches of our experts',
    prev: 'Past speeches',
    next: 'Upcoming speeches',
    process: 'Speeches are in the process of being described',
    come_back: 'Come back in a couple of days',
  },
};
