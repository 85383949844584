export interface PropTypes {
  className?: string;
  size?: number;
  color?: string;
  strokeWidth?: number;
}

export const defaultProps: Partial<PropTypes> = {
  className: '',
};
