/* eslint-disable no-useless-escape */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable no-restricted-globals */
import { graphql, useStaticQuery } from 'gatsby';
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';
import qs from 'query-string';
import { useIntl } from 'react-intl';
import Typograf from 'typograf';
import { has, omit } from 'ramda';
import { useLocation } from '@reach/router';
import { isEmpty } from '../utils/validators';
import { BREADCRUMB_SOURCES } from './breadcrumbs';

const tp = new Typograf({ locale: ['ru', 'en-US'] });

/**
 * usePathPrefix - used for sites, that not in /, returns pathPrefix for gatsby
 */
export const usePathPrefix = (): string => {
  const data = useStaticQuery(graphql`
    {
      site {
        pathPrefix
      }
    }
  `);
  return process.env.NODE_ENV === 'development' ? '' : data.site.pathPrefix;
};

/**
 * Adds Escape hotkey for component
 */

export const useCloseOnEscape = (
  onRequestClose: () => void,
  ignore_inputs = true
) => {
  const onEscape = useCallback(
    (event) => {
      if (event.key !== 'Escape') return;
      if (
        // eslint-disable-next-line camelcase
        ignore_inputs &&
        (event.target.tagName === 'INPUT' ||
          event.target.tagName === 'TEXTAREA')
      )
        return;

      onRequestClose();
    },
    [onRequestClose]
  );

  useEffect(() => {
    window.addEventListener('keyup', onEscape);

    return () => {
      window.removeEventListener('keyup', onEscape);
    };
  }, [onEscape]);
};

/**
 * useSSRSafeWindow returns SSR-safe window (or null)
 */
export const useSSRSafeWindow = () =>
  (typeof window !== 'undefined' && window) || null;

export const useGetQueryString = (location?: Location) => {
  const win = useSSRSafeWindow();

  let search = '';

  if (location) {
    search = location.search;
  } else if (win) {
    search = win.location.search;
  } else {
    search = '';
  }

  return qs.parse(search);
};

/**
 * useIsMenuCollapsed checks location querystring and returns true if menu should be collapsed
 */
export const useIsMenuCollapsed = (
  location?: Location
): [boolean, (collapsed: boolean) => void] => {
  const win = useSSRSafeWindow();

  if (!win) return [false, () => {}];

  const query = useGetQueryString(location);
  const { pathname } = location || win.location;

  const [isCollapsed, setIsCollapsed] = useState(!!query.nomenu);

  const collapseMenu = useCallback((collapsed: boolean) => {
    const params = qs.stringify({
      ...query,
      nomenu: collapsed ? '1' : undefined,
    });

    history.replaceState('', document.title, `${pathname}?${params}`);

    window.dispatchEvent(
      new Event(collapsed ? 'menu_collapsed' : 'menu_uncollapsed')
    );
  }, []);

  const onMenuCollapsed = useCallback(
    () => setIsCollapsed(true),
    [setIsCollapsed]
  );
  const onMenuUncollapsed = useCallback(
    () => setIsCollapsed(false),
    [setIsCollapsed]
  );

  useEffect(() => {
    window.addEventListener('menu_collapsed', onMenuCollapsed);
    window.addEventListener('menu_uncollapsed', onMenuUncollapsed);

    return () => {
      window.removeEventListener('menu_collapsed', onMenuCollapsed);
      window.removeEventListener('menu_uncollapsed', onMenuUncollapsed);
    };
  });

  return [isCollapsed, collapseMenu];
};

/**
 * useTlinkQuery returns query for TLink by adding 'nomenu' to query params of current page
 */
export const useTlinkQuery = (
  collapsed: boolean,
  params: Record<string, string | undefined>
): string => {
  if (!collapsed && !(params && Object.keys(params).length)) {
    return '';
  }

  const props = {
    ...params,
    nomenu: collapsed ? 1 : undefined,
  };

  return `?${qs.stringify(props)}`;
};

/**
 * useLocale returns current locale
 */
export const useLocale = () => {
  const intl = useIntl();
  return intl.locale;
};

/**
 * useTc returns fn, that can handle translations
 */
export const useTc = () => {
  const intl = useIntl();

  return (id: string): string => {
    try {
      return tp.execute(
        intl.formatMessage({
          id,
        })
      );
    } catch (e) {
      return id;
    }
  };
};

/**
 * useErrorsWithClearOnInput - required fields validation hook
 *
 * @return [errors, setErrors, fieldValidator]
 *
 * @param fields - dictionary of (required) fields
 * @param fieldError - custom field error
 * @param validationError - custom throwed error
 */
export const useErrorsWithClearOnInput = <T extends any = any>(
  fields: Record<any, any>,
  fieldError?: T,
  validationError?: string
): [
  Record<string, T>,
  Dispatch<SetStateAction<Record<string, T>>>,
  () => void
] => {
  const [errors, setErrors] = useState<Record<string, T>>({});

  Object.entries(fields).forEach(([key, val]) =>
    useEffect(() => {
      if (has(key, errors)) setErrors(omit([key], errors));
    }, [val])
  );

  const fieldValidator = useCallback(() => {
    const faulty = Object.entries(fields).reduce(
      (acc, [key, val]) => (isEmpty(val) ? { ...acc, [key]: fieldError } : acc),
      {}
    );

    setErrors(faulty);

    if (Object.values(faulty).length) {
      throw new Error(validationError || 'This field is required');
    }
  }, [fields]);

  return [errors, setErrors, fieldValidator];
};

/**
 * Resets captcha value and widget
 * @param setCaptcha
 */
export const useResetCaptcha = (setCaptcha: Dispatch<SetStateAction<string>>) =>
  useCallback(() => {
    const window = useSSRSafeWindow();

    setCaptcha('');

    if (window) {
      (window as any).grecaptcha.reset();
    }
  }, [setCaptcha]);

/**
 * useQueryFrom returns from prop of current query string
 */
export const useQueryFrom = (): string | undefined => {
  const location = useLocation();
  const prefix = usePathPrefix();
  const locale = useLocale();

  return qs
    .parse(location.search)
    .from?.toString()
    .replace(new RegExp(`^(${prefix})?(\/${locale})?`), '')
    .replace(/([^/]+)\/$/, '$1');
};

/**
 * useFromBreadcrumbs returns current breadcrumbs
 */
export const useFromBreadcrumbs = () => {
  const from = useQueryFrom();

  if (!from || !has(from, BREADCRUMB_SOURCES)) return [];

  return BREADCRUMB_SOURCES[from];
};
