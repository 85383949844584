/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable consistent-return */
/* eslint-disable no-useless-escape */
import React, { FC, Fragment, useCallback, useEffect, useState } from 'react';
import classnames from 'classnames';
import { useLocation, navigate } from '@reach/router';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { TLink } from '../../../i18n/TLink';
import { Logo } from '../Logo';
import { MENU_LINKS } from '../../../constants/menu';
import { t } from '../../../i18n';
import { BuyButton } from '../BuyButton';
import { SocialIcon } from '../SocialIcon';
import styles from './styles.module.scss';
import {
  useLocale,
  usePathPrefix,
  useSSRSafeWindow,
} from '../../../constants/hooks';
import { useGetMenuCurrent } from '../../../utils/menu';
import useScreen from '../../../utils/hooks/useScreen';

interface IProps {
  collapsed: boolean;
  toggleCollapsed: () => void;
  onlyLanguages?: string[];
}

const MainLayoutMenu: FC<IProps> = ({
  collapsed,
  toggleCollapsed,
  onlyLanguages,
}) => {
  const win = useSSRSafeWindow();
  const locale = useLocale();
  const location = useLocation();
  const prefix = usePathPrefix();
  const { isMobile } = useScreen();

  const [innerHeight, setInnerHeight] = useState(
    (win && win.innerHeight) || 768
  );
  const [raised, setRaised] = useState(false);

  const onWindowResize = useCallback(() => {
    setInnerHeight((win && win.innerHeight) || 768);
  }, [setInnerHeight]);

  const [current, currentSubitem] = useGetMenuCurrent(locale, location);
  const [stackedItem, setStackedItem] = useState(current);

  const toggleStacked = useCallback(
    (index: number) => () => {
      setStackedItem(index);
    },
    [setStackedItem]
  );

  const toggleRaised = useCallback(
    () => setRaised(!raised),
    [raised, setRaised]
  );

  const [isCanGoBack, setCanGoBack] = useState(false);

  let pathName: Array<string> = [];
  if (win) {
    pathName = win.location.pathname.split('/');
  }
  const PATH_LENGTH_RU = 4;
  const PATH_LENGTH_EN = 3;

  useEffect(() => {
    if (
      isMobile &&
      ((pathName[1] === 'ru' && pathName.length > PATH_LENGTH_RU) ||
        pathName.length > PATH_LENGTH_EN)
    ) {
      setCanGoBack(true);
    }
  }, [pathName]);

  const comeBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (!win) return;
    win.addEventListener('resize', onWindowResize);
    return () => win.removeEventListener('resize', onWindowResize);
  }, [onWindowResize]);

  useEffect(() => {
    if (!raised) return;
    disableBodyScroll(document.body);
    return () => enableBodyScroll(document.body);
  }, [raised]);

  return (
    <div
      className={classnames(styles.menu, {
        [styles.raised]: raised,
        [styles.collapsed]: collapsed,
      })}
      style={{ height: raised ? innerHeight : 50 }}
    >
      <div className={styles.menu__top}>
        {isCanGoBack && (
          <div className={styles.menu__back} onClick={comeBack}>
            <img src={`${prefix}/collapse.svg`} alt="" />
            {t('menu.back')}
          </div>
        )}

        <div className={styles.menu__logo}>
          <TLink
            to="/"
            style={{ display: isCanGoBack || raised ? 'none' : 'block' }}
          >
            <Logo />
          </TLink>
          <div className={styles.menu__langs}>&nbsp;</div>
        </div>

        <div className={styles.menu__collapse}>
          <div className={styles.collapse} onClick={toggleCollapsed}>
            <img src={`${prefix}/collapse.svg`} alt="" />
          </div>
        </div>

        <div className={styles.menu__burger} onClick={toggleRaised}>
          <span />
        </div>
      </div>

      <div className={styles.menu__uncollapse} onClick={toggleCollapsed}>
        <img src={`${prefix}/uncollapse.svg`} alt="" />
      </div>

      <div className={styles.menu__logo_rotated}>
        <TLink to="/" className={styles.menu__logo}>
          <Logo />
        </TLink>
      </div>

      <div className={styles.menu__content}>
        <ul className={styles.menu__links}>
          {MENU_LINKS.map((link, i) => (
            <Fragment key={i}>
              <li className={styles.link__head} onClick={toggleStacked(i)}>
                {t(link[0] as string)}
              </li>

              {(link[1] as string[][]).map((sublink, j) => {
                if (locale === 'en' && sublink[0] === 'menu.career') {
                  return;
                }
                return (
                  <li
                    key={j}
                    className={classnames({
                      [styles.stacked]: stackedItem === i,
                      [styles.active]: sublink[1] === currentSubitem,
                    })}
                  >
                    <TLink to={sublink[1]}>{t(sublink[0])}</TLink>
                  </li>
                );
              })}
            </Fragment>
          ))}
        </ul>

        <div className={styles.button}>
          <BuyButton>{t('menu.order_app')}</BuyButton>
        </div>

        <div className={styles.socials}>
          <SocialIcon
            onClick={() => {}}
            to="https://twitter.com/IceRockDev/"
            icon="twitter"
          />
          <SocialIcon
            onClick={() => {}}
            to="https://medium.com/icerock"
            icon="medium"
          />
          <SocialIcon
            onClick={() => {}}
            to="https://www.behance.net/IceRock"
            icon="behance"
          />
          <SocialIcon
            onClick={() => {}}
            to="https://github.com/icerockdev/"
            icon="github"
          />
          <SocialIcon
            onClick={() => {}}
            to="https://www.linkedin.com/company/icerock-development/"
            icon="linkedin"
          />
        </div>
      </div>
    </div>
  );
};

export { MainLayoutMenu };
