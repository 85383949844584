/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable camelcase */
/* eslint-disable no-empty-pattern */
import React, { FC, useState, useCallback, useEffect } from 'react';
import { useIntl } from 'react-intl';
import classnames from 'classnames';
import styles from './styles.module.scss';
import { t } from '../../../i18n';
import { Button } from '../Button';
import { Modal } from '../Modal';
import { useSSRSafeWindow } from '../../../constants/hooks';

interface IProps {}

const haveSeenTerms = () => {
  try {
    return localStorage.getItem('have_read_terms') === '1' || false;
  } catch (e) {
    return false;
  }
};

const TermsModal: FC<IProps> = ({}) => {
  const { messages } = useIntl();

  const [collapsed, setCollapsed] = useState(false);

  let locationSearch = '';
  const win = useSSRSafeWindow();
  if (win) {
    locationSearch = win.location.search;
  }

  useEffect(() => {
    if (locationSearch.indexOf('nomenu=1') !== -1) {
      setCollapsed(true);
    } else {
      setCollapsed(false);
    }
  }, [locationSearch]);

  const [shown, setShown] = useState(true);
  const [show_modal, setShowModal] = useState(false);

  const setSeenTerms = useCallback(() => {
    localStorage.setItem('have_read_terms', '1');
    setShown(true);
  }, [setShown]);

  const showModal = useCallback(() => setShowModal(true), [setShowModal]);
  const hideModal = useCallback(() => setShowModal(false), [setShowModal]);

  useEffect(() => {
    setTimeout(() => setShown(haveSeenTerms()), 500);
  }, []);

  if (shown) return <div className={styles.nope} />;

  return (
    <div className={classnames(styles.wrap, { [styles.collapsed]: collapsed })}>
      {show_modal && (
        <Modal onClose={hideModal}>
          <div className={styles.modal}>
            {t('terms.text')}{' '}
            <a
              href={messages['terms.link_url'] as string}
              target="_blank"
              rel="noreferrer"
            >
              {t('terms.link_text_short')}
            </a>
            <div className={styles.button}>
              <Button onClick={hideModal}>{t('terms.button')}</Button>
            </div>
          </div>
        </Modal>
      )}

      {!show_modal && (
        <div className={styles.content}>
          <div className={styles.text}>
            {t('terms.text')}{' '}
            <a
              href={messages['terms.link_url'] as string}
              target="_blank"
              rel="noreferrer"
            >
              {t('terms.link_text')}
            </a>
          </div>

          <div className={styles.text_short}>
            {t('terms.text_short')}
            <br />
            <a onClick={showModal}>{t('terms.link_text_more')}</a>
          </div>

          <div className={styles.button}>
            <Button onClick={setSeenTerms}>{t('terms.button')}</Button>
          </div>
        </div>
      )}
    </div>
  );
};

export { TermsModal };
