import { useMemo } from 'react';
import { MENU_LINKS } from '../constants/menu';
import { usePathPrefix } from '../constants/hooks';
import { DEFAULT_LOCALE } from '../i18n';

export const useGetMenuCurrent = (
  locale: string,
  location: Location
): [number, string] => {
  const prefix = usePathPrefix();
  const localePrefix = useMemo(
    () => (locale === DEFAULT_LOCALE ? '' : `/${locale}`),
    [locale]
  );

  // Finds current menu item
  const current = useMemo<number>(
    () =>
      (location &&
        location.pathname &&
        MENU_LINKS.findIndex((category) =>
          (category[1] as string[][]).some((link) => {
            if (
              link[1] === '/' &&
              location.pathname === `${prefix}${localePrefix}/`
            ) {
              return true;
            }

            return (
              link[1] !== '/' &&
              (`${prefix}${localePrefix}${link[1]}` === location.pathname ||
                location.pathname.indexOf(link[1]) >= 0)
            );
          })
        )) ||
      0,
    [location.pathname, localePrefix, locale, prefix]
  );

  // Find curent menu subitem
  const currentSubitem = useMemo<string>(() => {
    if (!location || !location.pathname) return '';
    if (location.pathname === `${prefix}${locale}`) return `${prefix}${locale}`;

    // flattening array
    const items = MENU_LINKS.reduce(
      (arr: string[][], el) => [...arr, ...(el as string[][][])[1]],
      []
    );

    const exact = items.findIndex(
      (link) => `${prefix}${localePrefix}${link[1]}` === location.pathname
    );

    if (exact >= 0) {
      return items[exact][1];
    }

    const similar = items.findIndex(
      (link) =>
        link[1] !== '/' &&
        location.pathname.indexOf(`${prefix}${localePrefix}${link[1]}`) > -1
    );

    if (similar >= 0) {
      return items[similar][1];
    }

    return '';
  }, [location.pathname, current, prefix, localePrefix, locale]);

  return [current, currentSubitem];
};
