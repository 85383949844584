import React, { FC, HTMLAttributes, useMemo } from 'react';
import { useIntl } from 'react-intl';
import TransitionLink from 'gatsby-plugin-transition-link';
import { DEFAULT_LOCALE } from '..';

type IProps = HTMLAttributes<HTMLAnchorElement> & {
  to: string;
  params?: Record<string, string | undefined>;
  locale?: string;
};

const TLink: FC<IProps> = ({
  to,
  children,
  locale: forcedLocale,
  params = {},
  ...props
}) => {
  const intl = useIntl();
  const locale = forcedLocale || intl.locale;

  if (to.indexOf('directions') !== -1 && to.indexOf('pages') !== -1) {
    // eslint-disable-next-line no-param-reassign
    params = { from: to.split('pages')[1]?.split('/')[1] };
  }
  const href = useMemo(
    () => (locale === DEFAULT_LOCALE ? `${to}` : `/${locale}${to}`),
    [locale, to]
  );

  const isOutLink = to.indexOf('https') !== -1;

  if (!to) return <>{children}</>;

  if (isOutLink) {
    return (
      <a
        href={to}
        {...props}
        rel="noreferrer"
        target="_blank"
        style={{ color: 'rgb(21, 21, 21)' }}
      >
        {children}
      </a>
    );
  }

  return (
    <TransitionLink
      to={href}
      exit={{
        length: 1,
      }}
      entry={{
        length: 0.5,
        delay: 0.25,
      }}
      {...props}
    >
      {children}
    </TransitionLink>
  );
};

export { TLink };
