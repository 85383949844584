import qs from 'query-string';

function setCookie(name: string, value: string, days: number) {
  let expires = '';
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = `; expires=${date.toUTCString()}`;
  }
  document.cookie = `${name}=${value || ''}${expires}`;
}

export const setUtmCookie = (days: number): void => {
  if (window.location.search.match('utm')) {
    const data = qs.parse(window.location.search);

    setCookie('utm_source', data.utm_source?.toString() || '', days);
    setCookie('utm_medium', data.utm_medium?.toString() || '', days);
    setCookie('utm_campaign', data.utm_campaign?.toString() || '', days);
    setCookie('utm_campaignid', data.utm_campaignid?.toString() || '', days);
    setCookie('utm_adgroup', data.utm_adgroup?.toString() || '', days);
    setCookie('utm_adgroupid', data.utm_adgroupid?.toString() || '', days);
    setCookie('utm_content', data.utm_content?.toString() || '', days);
    setCookie('utm_contentid', data.utm_contentid?.toString() || '', days);
    setCookie('utm_term', data.utm_term?.toString() || '', days);
    setCookie('utm_termid', data.utm_termid?.toString() || '', days);
    setCookie('yclid', data.yclid?.toString() || '', days);
  }
};

export const setLanguageCookie = (days: number): void => {
  if (window.location.search.match('language')) {
    const data = qs.parse(window.location.search);

    setCookie('language', data.language?.toString() || '', days);
  }
};
