import React from 'react';
import { Helmet } from 'react-helmet';
import { useSiteMetadata } from '../../utils/hooks/useSiteMetadata';

interface ISeo {
  title?: string;
  description?: string;
  keywords?: string;
  pathname?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children?: any;
}

export const SEO: React.FC<ISeo> = ({
  title,
  description,
  keywords,
  pathname,
  children,
}) => {
  const {
    title: defaultTitle,
    description: defaultDescription,
    keywords: defaultKeywords,
    image,
    siteUrl,
    twitterUsername,
  } = useSiteMetadata();

  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    keywords: keywords || defaultKeywords,
    image: `${siteUrl}${image}`,
    url: `${siteUrl}${pathname || ``}`,
    twitterUsername,
  };

  return (
    <Helmet>
      <title>{seo.title}</title>
      <meta name="description" content={seo.description} />
      <meta name="keywords" content={seo.keywords} />

      <meta property="og:title" content={seo.title} />
      <meta property="og:description" content={seo.description} />

      <meta property="og:url" content={seo.url} />
      <meta property="og:type" content="website" />
      <meta property="og:site_name" content="IceRock Development" />
      <meta property="og:locale" content="en_US" />

      <meta name="image" content={seo.image} />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={seo.title} />
      <meta name="twitter:url" content={seo.url} />
      <meta name="twitter:description" content={seo.description} />
      <meta name="twitter:image" content={seo.image} />
      <meta name="twitter:creator" content={seo.twitterUsername} />

      {children}
    </Helmet>
  );
};
