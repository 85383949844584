export const MENU_LINKS = [
  [
    'menu.about',
    [
      ['menu.company', '/'],
      ['menu.team', '/team/'],
      ['menu.career', '/career/'],
      ['menu.vacancy', '/vacancies/'],
      ['menu.contacts', '/contacts/'],
    ],
  ],
  [
    'menu.projects',
    [
      ['menu.directions', '/directions/'],
      ['menu.portfolio', '/cases/'],
      ['menu.reviews', '/reviews/'],
    ],
  ],
  [
    'menu.blogs',
    [
      ['menu.blog_kotlin', '/blog/kotlin-multiplatform'],
      ['menu.blog_mobile', '/blog/mobile'],
      ['menu.blog_uiux', '/blog/ui-ux'],
      ['menu.blog_company', '/blog/company-life'],
    ],
  ],
];

// Pages, from which we go to cases
export const PAGE_SOURCES = {
  ABOUT: '/',
  DIRECTIONS: '/directions',
  DIRECTIONS_DELIVERY: '/directions/delivery',
  DIRECTIONS_ECOMMERCE: '/directions/ecommerce',
  DIRECTIONS_ENTERTAINMENT: '/directions/entertainment',
  DIRECTIONS_FINTECH: '/directions/fintech',
  DIRECTIONS_IIKO: '/directions/iiko',
  DIRECTIONS_INTERNAL: '/directions/internal',
  DIRECTIONS_IOT: '/directions/iot',
  DIRECTIONS_PROMOTIONAL: '/directions/promotional',
  BLOG_KOTLIN_MULTIPLATFORM: '/blog/kotlin-multiplatform',
  BLOG_MOBILE: '/blog/mobile',
  BLOG_UIUX: '/blog/ui-ux',
  BLOG_COMPANY: '/blog/company-life',
  CASE: '/cases',
  SPEECHES: '/speeches',
  INTERNSHIP: '/internship',
  KMM: '/kmm',
};
