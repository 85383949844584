// eslint-disable-next-line @typescript-eslint/no-var-requires
const enGb = require('date-fns/locale/en-GB');

module.exports = {
  en: {
    path: 'en',
    locale: 'English',
    default: true,
    date: enGb.default,
  },
};
