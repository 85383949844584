import React, { FC, ReactNode, useCallback, useEffect } from 'react';
import classnames from 'classnames';
import { useLocation } from '@reach/router';
import styles from './styles.module.scss';
import { Trans } from '../../i18n';
import { useIsMenuCollapsed } from '../../constants/hooks';
import { TermsModal } from '../../components/main/TermsModal';
import { MainLayoutMenu } from '../../components/main/MainLayoutMenu';
import { setLanguageCookie, setUtmCookie } from '../../utils/setCookies';
import { SEO } from '../../components/seo/seo';

interface IProps {
  locale: string;
  title?: string;
  description?: string;
  keywords?: string;
  children?: ReactNode;
}

const MainLayout: FC<IProps> = ({
  title,
  description,
  keywords,
  children,
  locale,
}) => {
  const location = useLocation();
  const [collapsed, setCollapsed] = useIsMenuCollapsed(location);

  const toggleCollapsed = useCallback(() => {
    setCollapsed(!collapsed);
  }, [collapsed, setCollapsed]);

  useEffect(() => {
    setUtmCookie(30);
    setLanguageCookie(30);
  }, []);

  return (
    <>
      <Trans locale={locale}>
        <SEO title={title} description={description} keywords={keywords} />
        <div className={styles.wrap}>
          <MainLayoutMenu
            collapsed={collapsed}
            toggleCollapsed={toggleCollapsed}
          />

          <div
            className={classnames('content-window', styles.content, {
              [styles.collapsed]: collapsed,
              'main-menu-collapsed': collapsed,
            })}
          >
            <TermsModal />
            {children}
          </div>
        </div>
      </Trans>
    </>
  );
};

export { MainLayout };
