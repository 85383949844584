/* eslint-disable no-empty-pattern */
import React, { FC } from 'react';

interface IProps {}

const Logo: FC<IProps> = ({}) => (
  <svg viewBox="0 0 119 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.14482 0H0L0.000725236 22H5.14482V0Z" fill="#FFDB72" />
    <path
      d="M10.1544 18.8374C11.1582 19.853 12.3691 20.6431 13.7055 21.1548C15.1858 21.7289 16.7632 22.015 18.3519 21.9973C21.6039 22.0668 24.7345 20.769 26.9736 18.4231L24.2698 14.6164C23.4931 15.3896 22.5917 16.0281 21.6029 16.5055C20.6667 16.975 19.6343 17.2237 18.5857 17.2322C17.6618 17.2469 16.7461 17.0583 15.9041 16.68C15.1592 16.3504 14.505 15.8473 13.9969 15.2133C13.5191 14.6025 13.1509 13.9143 12.9083 13.1791C12.6585 12.4243 12.5327 11.6344 12.5358 10.8398C12.5311 10.0753 12.6494 9.31505 12.8861 8.58773C13.1179 7.88471 13.4771 7.22981 13.946 6.65542C14.4555 6.04707 15.1081 5.57304 15.8458 5.2753C16.7147 4.92186 17.6471 4.74868 18.5857 4.76637C19.5599 4.77872 20.5217 4.98623 21.4134 5.3765C22.3374 5.7554 23.1936 6.2804 23.949 6.93115L26.3747 3.00785C25.3379 2.00522 24.0939 1.23922 22.7291 0.763138C21.3262 0.257206 19.8451 -0.000900707 18.3527 0.000478143C16.7997 -0.0133483 15.2587 0.272812 13.8154 0.843071C12.4825 1.36232 11.2712 2.1486 10.2569 3.15305C9.26808 4.15339 8.48827 5.3385 7.96244 6.64002C7.40261 8.01025 7.11967 9.4767 7.12978 10.9556C7.11598 12.4534 7.38594 13.9403 7.92556 15.3387C8.42627 16.6412 9.18409 17.8308 10.1544 18.8374Z"
      fill="#FFDB72"
    />
    <path
      d="M42.1141 17.5267H33.4422L33.4414 13.2733H39.9091V8.8H33.4422L33.4414 4.47333H41.3791V0H28.1583L28.1495 22H42.1141V17.5267Z"
      fill="#FFDB72"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M46.8914 22V13.1986L52.1098 13.2L56.949 22H59.386L54.7369 13.5761C54.689 13.4925 54.646 13.411 54.6039 13.3312C54.4988 13.1322 54.3993 12.9438 54.2435 12.7594C55.4499 12.3808 56.4114 11.6352 57.108 10.6205C57.8685 9.53329 58.2391 7.91884 58.2101 6.60205C58.2406 5.47573 57.9961 3.61035 57.4123 2.63886C56.8607 1.75116 56.0516 1.04386 55.0877 0.606909C53.8353 0.149772 52.5006 -0.0527633 51.1653 0.0116923H44.6865V22H46.8914ZM46.8914 2.2H51.0808C52.029 2.15355 52.9435 2.3079 53.84 2.61335C54.5363 2.90901 55.1188 3.41277 55.5039 4.05228C55.9066 4.72561 56.0911 5.82017 56.0768 6.6C56.1334 7.76434 55.7149 9.28636 54.9206 10.1558C54.1168 10.9695 53.2657 11.0518 52.1098 11H46.8914V2.2Z"
      fill="#FFDB72"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M61.6496 16.4891C62.5415 18.1629 63.8661 19.5574 65.4804 20.5225C68.8566 22.4925 73.0082 22.4925 76.3844 20.5225C77.9978 19.5586 79.3203 18.1636 80.2079 16.4891C81.1317 14.7522 81.6045 12.8045 81.5814 10.8301C81.6038 8.9099 81.1302 7.01727 80.2079 5.34121C79.3085 3.70809 77.9862 2.3565 76.3844 1.43311C72.9929 -0.477704 68.8719 -0.477704 65.4804 1.43311C63.8777 2.3577 62.5533 3.70884 61.6496 5.34121C60.7226 7.0157 60.2463 8.90896 60.2687 10.8301C60.2456 12.8054 60.7211 14.7537 61.6496 16.4891ZM63.5111 6.31656C64.2233 4.98739 65.2819 3.88319 66.5708 3.12506C69.2794 1.57859 72.5854 1.57859 75.2941 3.12506C76.5818 3.88257 77.6384 4.98707 78.3471 6.31656C79.0873 7.70088 79.4662 9.25479 79.4477 10.8301C79.4672 12.4574 79.0891 14.0641 78.3471 15.5063C76.8975 18.3052 74.0414 20.0575 70.9291 20.0575C67.8168 20.0575 64.9607 18.3052 63.5111 15.5063C62.7655 14.0654 62.3856 12.458 62.4061 10.8301C62.3867 9.25412 62.7673 7.69961 63.5111 6.31656Z"
      fill="#FFDB72"
    />
    <path
      d="M88.5546 20.5069C90.2108 21.5164 92.1068 22.0327 94.0313 21.9983C96.996 22.0513 99.8532 20.8559 101.941 18.6888L100.764 17.2116C98.9729 18.9965 96.5819 20.0021 94.0886 20.0192C92.527 20.0461 90.9906 19.613 89.6603 18.7708C88.3887 17.9675 87.3616 16.8131 86.6955 15.439C86.0062 14.0069 85.6568 12.427 85.6761 10.829C85.6534 9.26372 86.0034 7.71656 86.6955 6.3226C87.3624 4.99721 88.3802 3.89323 89.6313 3.13839C90.9563 2.34221 92.4683 1.93593 94.0024 1.96389C95.1636 1.96905 96.3153 2.1789 97.4076 2.58432C98.4747 2.95594 99.4636 3.53264 100.322 4.28381L101.411 2.74987C100.447 1.80764 99.2892 1.10066 98.0235 0.681256C96.7092 0.229142 95.3323 -0.000538947 93.9466 0.00116274C92.0707 -0.0271505 90.2216 0.462081 88.5916 1.41802C87.028 2.33776 85.7452 3.68882 84.8878 5.31887C83.9964 7.01989 83.542 8.92707 83.5677 10.8595C83.542 12.8166 83.9857 14.7499 84.8595 16.4882C85.7045 18.1551 86.9841 19.5467 88.5546 20.5069Z"
      fill="#FFDB72"
    />
    <path
      d="M107.012 11.5133H110.54L116.523 22H118.919L112.24 10.4502L118.512 0H116.16L110.54 9.53333H107.012V0H104.954V22H107.012V11.5133Z"
      fill="#FFDB72"
    />
  </svg>
);

export { Logo };
