import { PAGE_SOURCES } from './menu';

// key is passed MarkdownPage component through ?from= query
// value is rendered at breadcrumbs, separated by delimiter
export const BREADCRUMB_SOURCES: Record<
  string,
  { title: string; url: string }[]
> = {
  [PAGE_SOURCES.ABOUT]: [{ title: 'menu.about', url: '/' }],
  [PAGE_SOURCES.DIRECTIONS]: [{ title: 'menu.directions', url: '/directions' }],
  [PAGE_SOURCES.DIRECTIONS_DELIVERY]: [
    { title: 'directions.areas_of_work_list', url: '/directions' },
    { title: 'directions.delivery', url: '/directions/delivery' },
  ],
  [PAGE_SOURCES.DIRECTIONS_ECOMMERCE]: [
    { title: 'directions.areas_of_work_list', url: '/directions' },
    { title: 'directions.ecommerce', url: '/directions/ecommerce' },
  ],
  [PAGE_SOURCES.DIRECTIONS_ENTERTAINMENT]: [
    { title: 'directions.areas_of_work_list', url: '/directions' },
    { title: 'directions.entertainment', url: '/directions/entertainment' },
  ],
  [PAGE_SOURCES.DIRECTIONS_FINTECH]: [
    { title: 'directions.areas_of_work_list', url: '/directions' },
    { title: 'directions.fintech', url: '/directions/fintech' },
  ],
  [PAGE_SOURCES.DIRECTIONS_IIKO]: [
    { title: 'directions.areas_of_work_list', url: '/directions' },
    { title: 'directions.iiko', url: '/directions/iiko' },
  ],
  [PAGE_SOURCES.DIRECTIONS_INTERNAL]: [
    { title: 'directions.areas_of_work_list', url: '/directions' },
    { title: 'directions.internal', url: '/directions/internal' },
  ],
  [PAGE_SOURCES.DIRECTIONS_IOT]: [
    { title: 'directions.areas_of_work_list', url: '/directions' },
    { title: 'directions.iot', url: '/directions/iot' },
  ],
  [PAGE_SOURCES.DIRECTIONS_PROMOTIONAL]: [
    { title: 'directions.areas_of_work_list', url: '/directions' },
    { title: 'directions.promotional', url: '/directions/promotional' },
  ],
  [PAGE_SOURCES.BLOG_KOTLIN_MULTIPLATFORM]: [
    { title: 'menu.blog_kotlin', url: PAGE_SOURCES.BLOG_KOTLIN_MULTIPLATFORM },
  ],
  [PAGE_SOURCES.BLOG_MOBILE]: [
    { title: 'menu.blog_mobile', url: PAGE_SOURCES.BLOG_MOBILE },
  ],
  [PAGE_SOURCES.BLOG_UIUX]: [
    { title: 'menu.blog_uiux', url: PAGE_SOURCES.BLOG_UIUX },
  ],
  [PAGE_SOURCES.BLOG_COMPANY]: [
    { title: 'menu.blog_company', url: PAGE_SOURCES.BLOG_COMPANY },
  ],
  [PAGE_SOURCES.CASE]: [{ title: 'menu.portfolio', url: '/cases/' }],
  [PAGE_SOURCES.SPEECHES]: [{ title: 'speeches.title', url: '/speeches/' }],
  [PAGE_SOURCES.INTERNSHIP]: [
    { title: 'internship.title.title', url: '/internship/' },
  ],
};
