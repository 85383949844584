import * as React from 'react';
import cn from 'classnames';

import { PropTypes, defaultProps } from '../IconProps';

const style = {
  width: '24px',
  height: '24px',
  animation: 'rotation .75s infinite linear',
};

const Component: React.FC<PropTypes> = ({
  className,
  size = 24,
  color = '#fff',
  ...rest
}: PropTypes) => (
  <div style={style}>
    <svg
      className={cn(className)}
      style={{ width: `${size}px`, height: `${size}px`, color }}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="currentColor"
      {...rest}
    >
      <path
        opacity={0.2}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 19a7 7 0 100-14 7 7 0 000 14zm0 3c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10z"
        fill="#422AA6"
      />
      <path d="M2 12C2 6.477 6.477 2 12 2v3a7 7 0 00-7 7H2z" fill="#422AA6" />
    </svg>
  </div>
);

Component.defaultProps = defaultProps;

export default Component;
