import * as React from 'react';
import cn from 'classnames';

import { PropTypes, defaultProps } from '../IconProps';

const Component: React.FC<PropTypes> = ({
  className,
  size = 24,
  color = '#fff',
  ...rest
}: PropTypes) => (
  <svg
    className={cn(className)}
    style={{ width: `${size}px`, height: `${size}px`, color }}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="currentColor"
    {...rest}
  >
    <path d="M8.012 22L6 19.986 13.976 12 6 4.014 8.012 2 18 12 8.012 22z" />
  </svg>
);

Component.defaultProps = defaultProps;

export default Component;
