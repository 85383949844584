import React, { FC, ReactChildren, ReactElement } from 'react';
import cn from 'classnames';
import styles from './styles.module.scss';
import { TLink } from '../../../i18n/TLink';

interface IProps {
  children?: ReactChildren | null | ReactElement;
  squared?: boolean;
}

const BuyButton: FC<IProps> = ({ children, squared }) => (
  <TLink
    to="/order"
    className={cn(squared ? styles.button__squared : styles.button)}
  >
    {children}
  </TLink>
);

export { BuyButton };
