import React, { FC, ReactNode } from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';
import { useCloseOnEscape, useIsMenuCollapsed } from '../../../constants/hooks';

interface IProps {
  children: ReactNode;
  onClose: () => void;
}

const Modal: FC<IProps> = ({ children, onClose }) => {
  const [collapsed] = useIsMenuCollapsed();

  useCloseOnEscape(onClose);

  return (
    <div
      className={classNames(styles.wrap, {
        [styles.wrap_collapsed]: collapsed,
      })}
    >
      {children}
    </div>
  );
};

export { Modal };
