import React, { FC, ReactElement, ReactChildren, HTMLAttributes } from 'react';
import cn from 'classnames';
import styles from './styles.module.scss';
import { Spinner } from '../../../common/icons/basic';

type IProps = HTMLAttributes<HTMLButtonElement> & {
  children?: ReactChildren | null | ReactElement;
  disabled?: boolean;
  gradientStyle?: boolean;
  isLoading?: boolean;
  fullWidth?: boolean;
  size?: 'normal' | 'big';
};

const Button: FC<IProps> = ({
  children,
  gradientStyle,
  disabled,
  isLoading,
  fullWidth,
  size = 'normal',
  ...props
}) => (
  // eslint-disable-next-line react/button-has-type
  <button
    className={cn('button', {
      [styles.button__gradient]: gradientStyle,
      [styles.button__disabled]: disabled,
      [styles.button__fullwidth]: fullWidth,
      [styles.button__big]: size === 'big',
    })}
    disabled={disabled}
    {...props}
  >
    {children}
    {isLoading && (
      <div className={styles.spinner__wrapper}>
        <Spinner />
      </div>
    )}
  </button>
);

export { Button };
