import { useMedia } from 'use-media';

// eslint-disable-next-line no-shadow
enum SCREENS {
  MOBILE_SMALL = '576px',
  MOBILE = '768px',
  TABLET = '1024px',
  DESKTOP_SMALL = '1440px',
}

type useScreenType = () => Record<
  | 'isMobile'
  | 'isTablet'
  | 'isDesktop'
  | 'isDesktopSmall'
  | 'isDesktopBig'
  | 'isMobileSmall',
  boolean
>;

const useScreen: useScreenType = () => {
  const isMobileSmall = useMedia(`(max-width: ${SCREENS.MOBILE_SMALL})`);
  const isMobile = useMedia(`(max-width: ${SCREENS.MOBILE})`);
  const isTablet = useMedia(`(max-width: ${SCREENS.TABLET})`);
  const isDesktopSmall = useMedia(`(max-width: ${SCREENS.DESKTOP_SMALL})`);
  const isDesktopBig = !isMobile && !isTablet && !isDesktopSmall;
  const isDesktop = isDesktopSmall || isDesktopBig;

  return {
    isMobile,
    isTablet,
    isDesktopSmall,
    isDesktopBig,
    isDesktop,
    isMobileSmall,
  };
};

export default useScreen;
